import * as types from "../constants";
import axios from "../../plugins/axios";
import { setSnackAction } from "../actions/snackActions";
import { setUserTimerRunning } from "../actions/userActions";
import _ from "lodash";
import caseTabs from "../../constants/caseTabs";
import {
    setBackdropOpen,
    setCaseResearchOpen,
    setCaseResearchSessionId,
    setExistingCaseResearchSession,
    setCaseResearchStart,
} from "../actions/caseActions";
import {
    emptyCall,
    emptyCallNote,
    emptyEmail as emptyEmailTemplate,
} from "../../constants/emptyActivities";
import requestStatus from "../../constants/requestStatus";
import callNoteModes from "../../constants/callNoteModes";
import { miscActivityGroups } from "../../constants/miscActivityGroupConstants";
import { nanoid } from "nanoid";
import { uploadStatus } from "../../constants/uploadStatus";
import caseTab from "../../constants/caseTab";
import { differenceInMinutes } from "date-fns"; 

const apiUrl = process.env.REACT_APP_CASENEST_API_URL;

const initialState = {
    isSearching: false,
    showEditor: false,
    currentCaseId: null,
    cases: {},
    timeEvents: [],
    currentTimeEvent: null,
    callNote: {
        issueFocus: false,
        adviceFocus: false,
        actionFocus: false,
        descriptionFocus: false,
    },
    internalNote: {
        noteFocus: false,
        descriptionFocus: false,
    },
    searchCriteria: {
        displayCaseSearchInTab: false,
        accountName: "",
        partnerNumber: "",
        partnershipId: null,
        modifiedDateFrom: null,
        modifiedDateTo: null,
        createdDateFrom: null,
        createdDateTo: null,
        adviserId: null,
        caseTypeId: null,
        employeeName: "",
        showClosed: false,
        primaryContact: "",
        caseDescription: "",
    },
    isCaseTimerRunning: false,
    currentCaseTotalMinutes: 0,
    confirmCompleteTask: {
        open: false,
        info: {
            channelType: null,
            sid: null,
            taskSid: null,
        },
        emailAllocated: false,
    },
    milestoneOpen: false,
    backdropOpen: false,
    confirmCloseOpen: false,
    caseResearch: {
        open: false,
        sessionId: null,
        start: null,
        existingSession: false,
    },
    processUnallocatedTimeRequestStatus: requestStatus.IDLE,
    getUnallocatedTimeRequestStatus: requestStatus.IDLE,
    unallocatedCaseTimeEventIds: [],
    unallocatedSeconds: null,
    fromAddress: '',
};

const emptyReply = {
    editorHtml: "",
    ccContacts: [],
    bccContacts: [],
    contacts: [],
    precedents: [],
    caseAttachmentsSelected: [],
    draftAttachmentsSelected: [],
    attachments: [],
    attachmentsLinked: [],
    subject: "",
    replyingTo: null,
    ccUserIds: [],
    bccUserIds: [],
    editingReplyChain: false,
    isEditingDraft: false,
    contactsNotKnown: [],
    sending: false,
    savingDraft: false,
    description: "",
    fromAddress: '',
};

const emptyCallAndNote = {
    call: emptyCall,
    text: emptyCallNote,
    open: false,
    caseActivityId: null,
    mode: callNoteModes.CALL,
    contact: null,
    outbound: false,
    saving: false,
    description: "",
};

const emptyInternalNote = {
    text: "",
    open: false,
    caseActivityId: null,
    saving: false,
    description: "",
};

const emptyEmail = {
    email: emptyEmailTemplate,
    open: false,
};

const emptyRetrospectiveMiscActivity = {
    miscActivityTypeId: "",
    startTime: null,
    hours: "",
    minutes: "",
    date: null,
    description: "",
    caseActivityId: null,
    existingFiles: [],
    newFiles: [],
    filesToDelete: [],
    notes: "",
};

const emptyCurrentMiscActivity = {
    miscActivityTypeId: "",
    description: "",
    open: false,
    caseActivityId: null,
    existingFiles: [],
    newFiles: [],
    filesToDelete: [],
    notes: "",
    saving: false,
};

const emptyMiscActivity = {
    open: false,
    tab: miscActivityGroups.CURRENT,
    retrospective: { ...emptyRetrospectiveMiscActivity },
    current: { ...emptyCurrentMiscActivity },
};

const emptyMeeting = {
    open: false,
    meeting: null
}

const emptyHistoryFilters = {
    dateFrom: null,
    dateTo: null,
    activities: {},
    directions: [],
    chargeables: [],
    users: [],
};

const initialAIState = {
    processing: false,
    threadId: null,
    messages: [],
    files: [],
    assistantId: null,
    postedMessageIds: [],
    thumbsUpIds: [],
    thumbsDownIds: [],
    currentMessage: ''
}

export const actionCreators = {
    getCase: (caseId, history) => async (dispatch, getState) => {
        if (getState().userReducer.isUserTimerRunning) {
            dispatch(setUserTimerRunning(false));
        }

        if (getState().caseReducer.cases[caseId]) {
            dispatch({
                type: types.SET_CURRENT_CASE,
                caseId,
            });
            history.push("/case");
            return;
        }

        const userId = getState().userReducer.userProfile.userId;

        dispatch(setBackdropOpen(true));
        var response = await axios.get(`${apiUrl}/getcase/${caseId}/${userId}`);
        dispatch(setBackdropOpen(false));
        if (response.status === 204) {
            dispatch(setSnackAction("Case not found!", "warning"));
        } else {
            dispatch({
                type: types.ADD_CASE,
                caseId: caseId,
                case: response.data,
                minutes: response.data.caseSummary.totalMinutes,
                totalActivityTime: response.data.caseSummary.totalActivityTime,
            });

            let messages = [];

            const account = getState().caseReducer.cases[caseId].account;

            if (account.keyAccount)
                messages.push("<strong>** KEY ACCOUNT **</strong>");

            if (account.risk === "Medium" || account.risk === "High")
                messages.push(`Risk: ${account.risk}`);
         
            const accountNote = account.noteText;

            if (accountNote)
                messages.push(`Client Note:<br/>${accountNote}`);            

            messages.length > 0 && dispatch(setSnackAction(messages.join("<br/><br/>"), "warning"));

            if (response.data.caseResearchSession) {
                dispatch(setCaseResearchStart(response.data.caseResearchSession.start));
                dispatch(setCaseResearchSessionId(response.data.caseResearchSession.sessionId));
                dispatch(setExistingCaseResearchSession(true));
                dispatch(setCaseResearchOpen(true));
            }

            history.push("/case");
        }
    },

    getAllCaseActivities: (caseId) => async (dispatch) => {
        var response = await axios.get(
            `${apiUrl}/getallcaseactivities/${caseId}?includeFilters=false`
        );

        dispatch({
            type: types.ADD_CASE_EVENTS,
            events: response.data.caseActivities,
            caseId: caseId,
        });

        if (response.data.caseActivityIds.length)
            dispatch({
                type: types.SET_CASE_ACTIVITY_IDS,
                caseActivityIds: response.data.caseActivityIds,
                caseId: caseId,
            });
    },    
};

const reducer = (state, action) => {
    state = state || initialState;

    switch (action.type) {
        case types.SET_CASE_IS_SEARCHING: {
            return {
                ...state,
                isSearching: action.isSearching,
            };
        }

        case types.SET_CRITERIA_EMPLOYEE: {
            return {
                ...state,
                searchCriteria: {
                    ...state.searchCriteria,
                    employeeName: action.employee,
                },
            };
        }

        case types.SET_CRITERIA_MODIFIED_DATE_FROM: {
            return {
                ...state,
                searchCriteria: {
                    ...state.searchCriteria,
                    modifiedDateFrom: action.modifiedDateFrom,
                },
            };
        }

        case types.SET_CRITERIA_MODIFIED_DATE_TO: {
            return {
                ...state,
                searchCriteria: {
                    ...state.searchCriteria,
                    modifiedDateTo: action.modifiedDateTo,
                },
            };
        }

        case types.SET_CRITERIA_CREATED_DATE_FROM: {
            return {
                ...state,
                searchCriteria: {
                    ...state.searchCriteria,
                    createdDateFrom: action.createdDateFrom,
                },
            };
        }

        case types.SET_CRITERIA_CREATED_DATE_TO: {
            return {
                ...state,
                searchCriteria: {
                    ...state.searchCriteria,
                    createdDateTo: action.createdDateTo,
                },
            };
        }

        case types.SET_CRITERIA_PARTNERSHIP_ID: {
            return {
                ...state,
                searchCriteria: {
                    ...state.searchCriteria,
                    partnershipId: action.partnershipId,
                },
            };
        }

        case types.SET_CRITERIA_ADVISER_ID: {
            return {
                ...state,
                searchCriteria: {
                    ...state.searchCriteria,
                    adviserId: action.adviserId,
                },
            };
        }

        case types.SET_CRITERIA_CASE_TYPE_ID: {
            return {
                ...state,
                searchCriteria: {
                    ...state.searchCriteria,
                    caseTypeId: action.caseTypeId,
                },
            };
        }

        case types.SET_CRITERIA_ACCOUNT_NAME: {
            return {
                ...state,
                searchCriteria: {
                    ...state.searchCriteria,
                    accountName: action.accountName,
                },
            };
        }

        case types.SET_CRITERIA_PARTNER_NUMBER: {
            return {
                ...state,
                searchCriteria: {
                    ...state.searchCriteria,
                    partnerNumber: action.partnerNumber,
                },
            };
        }

        case types.SET_CRITERIA_PRIMARY_CONTACT: {
            return {
                ...state,
                searchCriteria: {
                    ...state.searchCriteria,
                    primaryContact: action.primaryContact,
                },
            };
        }

        case types.SET_CRITERIA_CASE_DESCRIPTION: {
            return {
                ...state,
                searchCriteria: {
                    ...state.searchCriteria,
                    caseDescription: action.caseDescription,
                },
            };
        }

        case types.SET_DISPLAY_CASE_SEARCH_IN_TAB: {
            return {
                ...state,
                searchCriteria: {
                    ...state.searchCriteria,
                    displayCaseSearchInTab: action.display,
                },
            };
        }

        case types.SET_DISPLAY_CLOSED_CASES: {
            return {
                ...state,
                searchCriteria: {
                    ...state.searchCriteria,
                    showClosed: action.displayClosed,
                },
            };
        }

        case types.SET_CURRENT_CASE: {
            return {
                ...state,
                currentCaseId: parseInt(action.caseId),
            };
        }

        case types.SET_CASE_VIEWSTATE: {
            let c = state.cases[action.caseId];
            return {
                ...state,
                cases: {
                    ...state.cases,
                    [action.caseId]: {
                        ...c,
                        viewState: action.viewState,
                        reply:
                            action.viewState.mode === caseTabs.normal
                                ? emptyReply
                                : c.reply,
                    },
                },
            };
        }

        case types.REMOVE_CASE_TAB: {
            let cases = _.omit(state.cases, action.caseId);

            let keys = Object.keys(cases);

            return {
                ...state,
                cases: cases,
                currentCaseId:
                    keys.length === 0
                        ? null
                        : cases[keys[0]].caseSummary.caseId,
            };
        }

        case types.ADD_CASE: {
            return {
                ...state,
                currentCaseId: parseInt(action.caseId),
                cases: {
                    ...state.cases,
                    [action.caseId]: {
                        ...action.case,
                        viewState: { currentTab: caseTab.DETAILS, mode: caseTabs.normal },
                        aiState: {
                            ...initialAIState,
                            threadId: action.case.aiThreadId === 'undefined' ? null : action.case.aiThreadId
                        },
                        reply: emptyReply,
                        callNote: emptyCallAndNote,
                        internalNote: emptyInternalNote,
                        email: emptyEmail,
                        caseActivityIds: [],
                        minutes: action.minutes || 0,
                        miscActivity: emptyMiscActivity,
                        meeting: emptyMeeting,
                        emails: [],
                        caseHistory: {
                            mode: "view",
                            selectAllChecked: false,
                            downloadCaseActivityIds: [],
                        },
                        totalActivityTime: action.totalActivityTime || 0,
                        draftFiles: null,
                        draftEmail: action.case.draftEmail
                            ? {
                                  ...action.case.draftEmail,
                                  attachments:
                                      action.case.draftEmail.attachments
                                          .filter((a) => !a.isCaseFile)
                                          .map((a) => ({
                                              id: nanoid(),
                                              file: a,
                                          })),
                                  caseAttachmentsSelected:
                                      action.case.draftEmail.attachments.filter(
                                          (a) => a.isCaseFile
                                      ),
                              }
                            : null,
                        historyFilters: emptyHistoryFilters,
                    },
                },
            };
        }

        case types.SET_ADVISERID: {
            let c = state.cases[action.caseId];

            return {
                ...state,
                cases: {
                    ...state.cases,
                    [action.caseId]: {
                        ...c,
                        caseSummary: {
                            ...c.caseSummary,
                            currentAdviserId: action.adviserId,
                        },
                    },
                },
            };
        }

        case types.SET_CASE_TYPE_AND_STAGE: {
            let c = state.cases[action.caseId];

            return {
                ...state,
                cases: {
                    ...state.cases,
                    [action.caseId]: {
                        ...c,
                        caseSummary: {
                            ...c.caseSummary,
                            caseTypeId: action.caseTypeId,
                            currentStageId: action.currentStageId,
                            isET: action.isET,
                        },
                    },
                },
            };
        }

        case types.SET_CASE_CLOSED: {
            let c = state.cases[action.payload.caseId];

            return {
                ...state,
                cases: {
                    ...state.cases,
                    [action.payload.caseId]: {
                        ...c,
                        caseSummary: {
                            ...c.caseSummary,
                            dateClosed: action.payload.closureDate,
                            caseOutcomeId: action.payload.caseOutcomeId,
                        },
                    },
                },
            };
        }

        case types.SET_CASE_PINNED: {
            let c = state.cases[action.payload.caseId];

            return {
                ...state,
                cases: {
                    ...state.cases,
                    [action.payload.caseId]: {
                        ...c,
                        caseSummary: {
                            ...c.caseSummary,
                            isPinned: action.payload.isPinned,
                        },
                    },
                },
            };
        }

        case types.SET_CASE_CONTACTS: {
            let c = state.cases[action.caseId];

            return {
                ...state,
                cases: {
                    ...state.cases,
                    [action.caseId]: {
                        ...c,
                        contacts: action.contacts,
                    },
                },
            };
        }

        case types.SET_CASE_ADVISERS: {
            let c = state.cases[action.caseId];

            return {
                ...state,
                cases: {
                    ...state.cases,
                    [action.caseId]: {
                        ...c,
                        advisers: action.advisers,
                    },
                },
            };
        }        

        case types.SET_REPLY_CONTACTS: {
            let c = state.cases[action.caseId];

            let contacts = action.contacts;

            contacts.forEach((contact) => {
                let emails = [];
                if (contact.email)
                    emails.push(contact.email);

                if (contact.email2)
                    emails.push(contact.email2);

                contact.emails = emails;

                const existingContact = c.reply.contacts.find((x) => x.contactId === contact.contactId);

                contact.toIndex = existingContact ? existingContact.toIndex : 0;
            });

            return {
                ...state,
                cases: {
                    ...state.cases,
                    [action.caseId]: {
                        ...c,
                        reply: {
                            ...c.reply,
                            contacts: contacts,
                        },
                    },
                },
            };
        }

        case types.SET_REPLY_INT_CCS: {
            let c = state.cases[action.caseId];
            return {
                ...state,
                cases: {
                    ...state.cases,
                    [action.caseId]: {
                        ...c,
                        reply: {
                            ...c.reply,
                            ccUserIds: action.userIds,
                        },
                    },
                },
            };
        }

        case types.SET_REPLY_NON_CONTACTS: {
            let c = state.cases[action.caseId];
            let allEmails = action.nonContacts;

            //we don't want to reply to the internal address
            const internalEmailAdds = [
                "legal_cms_test@elliswhittam.onmicrosoft.com",
                "legal_cms_dev@elliswhittam.onmicrosoft.com",
                "legal_cms_qa@elliswhittam.onmicrosoft.com",
                "legal_cms_live@elliswhittam.onmicrosoft.com",
            ];
            let emails =
                allEmails &&
                allEmails.filter((x) => !internalEmailAdds.includes(x));

            let nonContacts = emails;

            return {
                ...state,
                cases: {
                    ...state.cases,
                    [action.caseId]: {
                        ...c,
                        reply: {
                            ...c.reply,
                            contactsNotKnown: nonContacts,
                        },
                    },
                },
            };
        }

        case types.SET_REPLY_CC_NON_CONTACTS: {
            let c = state.cases[action.caseId];

            let allEmails = action.emails;

            //we don't want to reply to the internal address
            const internalEmailAdds = [
                "legal_cms_test@elliswhittam.onmicrosoft.com",
                "legal_cms_dev@elliswhittam.onmicrosoft.com",
                "legal_cms_qa@elliswhittam.onmicrosoft.com",
                "legal_cms_live@elliswhittam.onmicrosoft.com",
            ];
            let emails =
                allEmails &&
                allEmails.filter((x) => !internalEmailAdds.includes(x));

            return {
                ...state,
                cases: {
                    ...state.cases,
                    [action.caseId]: {
                        ...c,
                        reply: {
                            ...c.reply,
                            ccNonContacts: emails,
                        },
                    },
                },
            };
        }        

        case types.SET_PERMISSION_USERS: {
            let c = state.cases[action.caseId];
            return {
                ...state,
                cases: {
                    ...state.cases,
                    [action.caseId]: {
                        ...c,
                        permission: {
                            ...c.permission,
                            users: action.users,
                        },
                    },
                },
            };
        }

        case types.SET_CASE_PERMISSION_TYPE: {
            let c = state.cases[action.caseId];

            return {
                ...state,
                cases: {
                    ...state.cases,
                    [action.caseId]: {
                        ...c,
                        permission: {
                            ...c.permission,
                            permissionTypeId: action.permissionTypeId,
                            users: [],
                        },
                    },
                },
            };
        }

        case types.SET_REPLY_EDITOR_HTML: {
            let c = state.cases[action.payload.caseId];

            return {
                ...state,
                cases: {
                    ...state.cases,
                    [action.payload.caseId]: {
                        ...c,
                        reply: {
                            ...c.reply,
                            editorHtml: action.payload.editorHtml,
                        },
                    },
                },
            };
        }


        case types.SET_REPLY_FROM_ADDRESS: {
            let c = state.cases[action.payload.caseId];

            return {
                ...state,
                cases: {
                    ...state.cases,
                    [action.payload.caseId]: {
                        ...c,
                        reply: {
                            ...c.reply,
                            fromAddress: action.payload.fromAddress,
                        },
                    },
                },
            };
        }

        case types.ADD_REPLY_PRECEDENT: {
            let c = state.cases[action.caseId];

            return {
                ...state,
                cases: {
                    ...state.cases,
                    [action.caseId]: {
                        ...c,
                        reply: {
                            ...c.reply,
                            precedents: [
                                ...c.reply.precedents,
                                action.precedent,
                            ],
                        },
                    },
                },
            };
        }

        case types.REMOVE_REPLY_PRECEDENTS: {
            let c = state.cases[action.payload.caseId];

            return {
                ...state,
                cases: {
                    ...state.cases,
                    [action.payload.caseId]: {
                        ...c,
                        reply: {
                            ...c.reply,
                            precedents: c.reply.precedents.filter(
                                (p) =>
                                    !action.payload.redundantPrecedentIds.includes(
                                        p.precedentId
                                    )
                            ),
                        },
                    },
                },
            };
        }

        case types.UPDATE_CASE_PRECEDENT_NAME: {
            let c = state.cases[action.caseId];

            return {
                ...state,
                cases: {
                    ...state.cases,
                    [action.caseId]: {
                        ...c,
                        reply: {
                            ...c.reply,
                            precedents: c.reply.precedents.map((p) =>
                                p.precedentId === action.precedentId
                                    ? { ...p, name: action.newPrecedentName }
                                    : p
                            ),
                        },
                    },
                },
            };
        }

        case types.ADD_REPLY_ATTACHMENT: {
            let c = state.cases[action.caseId];

            return {
                ...state,
                cases: {
                    ...state.cases,
                    [action.caseId]: {
                        ...c,
                        reply: {
                            ...c.reply,
                            attachments: [
                                ...c.reply.attachments,
                                action.precedent,
                            ],
                        },
                    },
                },
            };
        }

        case types.REMOVE_REPLY_PRECEDENT: {
            let c = state.cases[action.caseId];

            return {
                ...state,
                cases: {
                    ...state.cases,
                    [action.caseId]: {
                        ...c,
                        reply: {
                            ...c.reply,
                            precedents: c.reply.precedents.filter(
                                (p) => p.precedentId !== action.precedentId
                            ),
                        },
                    },
                },
            };
        }

        case types.REMOVE_REPLY_ATTACHMENT: {
            let c = state.cases[action.caseId];

            return {
                ...state,
                cases: {
                    ...state.cases,
                    [action.caseId]: {
                        ...c,
                        reply: {
                            ...c.reply,
                            attachmentsLinked: c.reply.attachmentsLinked.filter(
                                (p) => p.name !== action.attachmentName
                            ),
                        },
                    },
                },
            };
        }

        case types.SET_PRECEDENT_DOCUMENT_HTML: {
            let c = state.cases[action.caseId];

            return {
                ...state,
                cases: {
                    ...state.cases,
                    [action.caseId]: {
                        ...c,
                        reply: {
                            ...c.reply,
                            precedents: c.reply.precedents.map((p) =>
                                p.precedentId === action.precedentId
                                    ? { ...p, htmlContent: action.htmlContent }
                                    : p
                            ),
                        },
                    },
                },
            };
        }

        case types.SET_REPLY_ATTACHMENTS: {
            let c = state.cases[action.caseId];

            return {
                ...state,
                cases: {
                    ...state.cases,
                    [action.caseId]: {
                        ...c,
                        reply: {
                            ...c.reply,
                            attachments: action.attachments,
                        },
                    },
                },
            };
        }

        case types.SET_REPLY_ATTACHMENT_AZURE_FILE_IDS: {
            let c = state.cases[action.caseId];
            const updatedAttachments = c.reply.attachments.map((attachment) => {
                const update = action.uploadedAttachments.find(
                    (x) => x.filename === attachment.file.name
                );

                if (update) attachment["azureFileId"] = update.azureFileId;

                return attachment;
            });

            return {
                ...state,
                cases: {
                    ...state.cases,
                    [action.caseId]: {
                        ...c,
                        reply: {
                            ...c.reply,
                            attachments: updatedAttachments,
                        },
                    },
                },
            };
        }

        case types.SET_REPLY_ATTACHMENTS_LINKED: {
            let c = state.cases[action.caseId];

            return {
                ...state,
                cases: {
                    ...state.cases,
                    [action.caseId]: {
                        ...c,
                        reply: {
                            ...c.reply,
                            attachmentsLinked: [
                                ...c.reply.attachmentsLinked,
                                {
                                    precedentId: action.precedentId,
                                    name: action.name,
                                },
                            ],
                        },
                    },
                },
            };
        }

        case types.ADD_CASE_EVENT: {
            let c = state.cases[action.caseId];

            console.log({ event: action.event });

            return {
                ...state,
                cases: {
                    ...state.cases,
                    [action.caseId]: {
                        ...c,
                        events: [action.event, ...c.events].sort(
                            (a, b) =>
                                new Date(b.eventTime) - new Date(a.eventTime)
                        ),
                        totalActivityTime:
                            c.totalActivityTime +
                            (action.event.itemType !== "InternalNote"
                                ? action.event.duration ?? 0
                                : 0),
                    },
                },
            };
        }

        case types.ADD_CASE_EVENTS: {
            let c = state.cases[action.caseId];

            return {
                ...state,
                cases: {
                    ...state.cases,
                    [action.caseId]: {
                        ...c,
                        events: action.events,
                    },
                },
            };
        }

        case types.UPDATE_CASE_EVENT_CHARGEABLE: {
            const c = state.cases[action.caseId];
            const newEvents = c.events.map((e) =>
                e.caseActivityId === action.caseActivityId
                    ? {
                        ...e,
                        isChargeable: action.isChargeable,
                        isExcluded: action.isExcluded
                    }
                    : e
            );

            return {
                ...state,
                cases: {
                    ...state.cases,
                    [action.caseId]: {
                        ...c,
                        events: [...newEvents],
                    },
                },
            };
        }

        case types.SET_CASE_EMPLOYEES: {
            let c = state.cases[action.caseId];

            return {
                ...state,
                cases: {
                    ...state.cases,
                    [action.caseId]: {
                        ...c,
                        employees: action.employees,
                    },
                },
            };
        }

        case types.ADD_CLAIM: {
            let c = state.cases[action.caseId];

            return {
                ...state,
                cases: {
                    ...state.cases,
                    [action.caseId]: {
                        ...c,
                        caseSummary: {
                            ...c.caseSummary,
                            claims: [
                                ...c.caseSummary.claims,
                                {
                                    claimId: action.claimId,
                                    claimTypeId: action.claimTypeId,
                                },
                            ],
                        },
                    },
                },
            };
        }

        case types.DELETE_CLAIM: {
            let c = state.cases[action.caseId];

            return {
                ...state,
                cases: {
                    ...state.cases,
                    [action.caseId]: {
                        ...c,
                        caseSummary: {
                            ...c.caseSummary,
                            claims: c.caseSummary.claims.filter(
                                (c) => c.claimId !== action.claimId
                            ),
                        },
                    },
                },
            };
        }

        case types.UPDATE_CLAIM: {
            let c = state.cases[action.caseId];

            return {
                ...state,
                cases: {
                    ...state.cases,
                    [action.caseId]: {
                        ...c,
                        caseSummary: {
                            ...c.caseSummary,
                            claims: c.caseSummary.claims.map((c) =>
                                c.claimId === action.claimId
                                    ? {
                                          ...c,
                                          claimTypeId: action.claimTypeId,
                                      }
                                    : c
                            ),
                        },
                    },
                },
            };
        }

        case types.SET_CLAIMS: {
            let c = state.cases[action.caseId];

            return {
                ...state,
                cases: {
                    ...state.cases,
                    [action.caseId]: {
                        ...c,
                        caseSummary: {
                            ...c.caseSummary,
                            claims: action.claims,
                        },
                    },
                },
            };
        }

        case types.UPDATE_ET_CASE: {
            let c = state.cases[action.caseId];

            return {
                ...state,
                cases: {
                    ...state.cases,
                    [action.caseId]: {
                        ...c,
                        caseSummary: {
                            ...c.caseSummary,
                            etCaseNum: action.etCaseNum,
                            venueId: action.venueId,
                            subVenue: action.subVenue,
                        },
                    },
                },
            };
        }

        case types.SET_COMPLETED_STAGE_TASKS: {
            let c = state.cases[action.caseId];

            return {
                ...state,
                cases: {
                    ...state.cases,
                    [action.caseId]: {
                        ...c,
                        caseSummary: {
                            ...c.caseSummary,
                            completedStageTasks: action.tasks,
                        },
                    },
                },
            };
        }

        case types.UNASSIGN_EMAIL: {
            let c = state.cases[action.prevCaseId];

            if (c) {
                return {
                    ...state,
                    cases: {
                        ...state.cases,
                        [action.prevCaseId]: {
                            ...c,
                            events: c.events.filter(
                                (e) =>
                                    e.caseActivityId !== action.caseActivityId
                            ),
                        },
                    },
                };
            }

            return state;
        }

        case types.SET_TIME_EVENT: {
            return {
                ...state,
                currentTimeEvent: action.e,
            };
        }

        case types.ADD_TIME_EVENT: {
            return {
                ...state,
                timeEvents: [...state.timeEvents, action.e],
            };
        }

        case types.CLEAR_TIME_EVENTS: {
            return {
                ...state,
                timeEvents: [],
            };
        }

        case types.SET_CASE_WINDOW_STATUS: {
            return {
                ...state,
                caseWindowStatus: action.status,
            };
        }

        case types.SET_FLEX_WINDOW_STATUS: {
            return {
                ...state,
                flexWindowStatus: action.status,
            };
        }

        case types.SET_CALL_NOTE_ISSUE_FOCUS: {
            return {
                ...state,
                callNote: {
                    ...state.callNote,
                    issueFocus: action.isFocus,
                },
            };
        }

        case types.SET_CALL_NOTE_ADVICE_FOCUS: {
            return {
                ...state,
                callNote: {
                    ...state.callNote,
                    adviceFocus: action.isFocus,
                },
            };
        }

        case types.SET_CALL_NOTE_ACTION_FOCUS: {
            return {
                ...state,
                callNote: {
                    ...state.callNote,
                    actionFocus: action.isFocus,
                },
            };
        }

        case types.SET_CASE_TIMER_RUNNING: {
            return {
                ...state,
                isCaseTimerRunning: action.isRunning,
            };
        }

        case types.SET_CALL_NOTE_CALL: {
            let c = state.cases[action.caseId];

            return {
                ...state,
                cases: {
                    ...state.cases,
                    [action.caseId]: {
                        ...c,
                        callNote: {
                            ...c.callNote,
                            call: action.call,
                        },
                    },
                },
            };
        }

        case types.SET_CALL_NOTE_TEXT: {
            let c = state.cases[action.caseId];

            return {
                ...state,
                cases: {
                    ...state.cases,
                    [action.caseId]: {
                        ...c,
                        callNote: {
                            ...c.callNote,
                            text: action.text,
                        },
                    },
                },
            };
        }

        case types.SET_CALL_NOTE_OPEN: {
            let c = state.cases[action.caseId];

            return {
                ...state,
                cases: {
                    ...state.cases,
                    [action.caseId]: {
                        ...c,
                        callNote: {
                            ...c.callNote,
                            open: action.isOpen,
                        },
                        internalNote: {
                            ...c.internalNote,
                            open: false,
                        },
                        email: {
                            ...c.email,
                            open: false,
                        },
                        miscActivity: {
                            ...c.miscActivity,
                            open: false,
                        },
                        meeting: {
                            ...c.meeting,
                            open: false,
                        },
                    },
                },
            };
        }

        case types.SET_CALL_NOTE_CASE_ACTIVITY_ID: {
            let c = state.cases[action.caseId];

            return {
                ...state,
                cases: {
                    ...state.cases,
                    [action.caseId]: {
                        ...c,
                        callNote: {
                            ...c.callNote,
                            caseActivityId: action.caseActivityId,
                        },
                    },
                },
            };
        }

        case types.UPDATE_CALL_NOTE: {
            let c = state.cases[state.currentCaseId];

            return {
                ...state,
                cases: {
                    ...state.cases,
                    [state.currentCaseId]: {
                        ...c,
                        events: c.events.map((e) =>
                            e.caseActivityId === action.caseActivityId
                                ? {
                                      ...e,
                                      issue: action.text.issue,
                                      advice: action.text.advice,
                                      action: action.text.action,
                                      duration: e.duration + action.duration,
                                      activityDescription: action.description,
                                  }
                                : e
                        ),
                        totalActivityTime: c.totalActivityTime + action.duration,
                    },
                },
            };
        }

        case types.SET_CALL_NOTE_DESCRIPTION: {
            let c = state.cases[state.currentCaseId];

            return {
                ...state,
                cases: {
                    ...state.cases,
                    [state.currentCaseId]: {
                        ...c,
                        callNote: {
                            ...c.callNote,
                            description: action.description,
                        },
                    },
                },
            };
        }

        case types.SET_CALL_NOTE_DESCRIPTION_FOCUS: {
            return {
                ...state,
                callNote: {
                    ...state.callNote,
                    descriptionFocus: action.isFocus,
                },
            };
        }

        case types.SET_REPLY_SUBJECT: {
            let c = state.cases[state.currentCaseId];

            return {
                ...state,
                cases: {
                    ...state.cases,
                    [state.currentCaseId]: {
                        ...c,
                        reply: {
                            ...c.reply,
                            subject: action.subject,
                        },
                    },
                },
            };
        }

        case types.SET_REPLY_DESCRIPTION: {
            let c = state.cases[state.currentCaseId];

            return {
                ...state,
                cases: {
                    ...state.cases,
                    [state.currentCaseId]: {
                        ...c,
                        reply: {
                            ...c.reply,
                            description: action.description,
                        },
                    },
                },
            };
        }

        case types.SET_REPLY_PRECEDENTS: {
            let c = state.cases[state.currentCaseId];

            return {
                ...state,
                cases: {
                    ...state.cases,
                    [state.currentCaseId]: {
                        ...c,
                        reply: {
                            ...c.reply,
                            precedents: action.precedents,
                        },
                    },
                },
            };
        }

        case types.SET_CASE_CONTRACT: {
            let c = state.cases[action.caseId];

            return {
                ...state,
                cases: {
                    ...state.cases,
                    [action.caseId]: {
                        ...c,
                        caseSummary: {
                            ...c.caseSummary,
                            contractId: action.contractId,
                            isUnitisedTime: action.isUnitisedTime,
                        },
                    },
                },
            };
        }

        case types.SET_INTERNAL_NOTE_OPEN: {
            let c = state.cases[action.caseId];

            return {
                ...state,
                cases: {
                    ...state.cases,
                    [action.caseId]: {
                        ...c,
                        internalNote: {
                            ...c.internalNote,
                            open: action.isOpen,
                        },
                        callNote: {
                            ...c.callNote,
                            open: false,
                        },
                        email: {
                            ...c.email,
                            open: false,
                        },
                        miscActivity: {
                            ...c.miscActivity,
                            open: false,
                        },
                        meeting: {
                            ...c.meeting,
                            open: false,
                        },
                    },
                },
            };
        }

        case types.SET_INTERNAL_NOTE_TEXT: {
            let c = state.cases[action.caseId];

            return {
                ...state,
                cases: {
                    ...state.cases,
                    [action.caseId]: {
                        ...c,
                        internalNote: {
                            ...c.internalNote,
                            text: action.text,
                        },
                    },
                },
            };
        }

        case types.SET_INTERNAL_NOTE_DESCRIPTION: {
            let c = state.cases[action.payload.caseId];

            return {
                ...state,
                cases: {
                    ...state.cases,
                    [action.payload.caseId]: {
                        ...c,
                        internalNote: {
                            ...c.internalNote,
                            description: action.payload.description,
                        },
                    },
                },
            };
        }

        case types.SET_INTERNAL_NOTE_FOCUS: {
            return {
                ...state,
                internalNote: {
                    ...state.internalNote,
                    noteFocus: action.hasFocus,
                },
            };
        }

        case types.SET_INTERNAL_NOTE_DESCRIPTION_FOCUS: {
            return {
                ...state,
                internalNote: {
                    ...state.internalNote,
                    descriptionFocus: action.hasFocus,
                },
            };
        }

        case types.UPDATE_INTERNAL_NOTE: {
            let c = state.cases[state.currentCaseId];
            return {
                ...state,
                cases: {
                    ...state.cases,
                    [state.currentCaseId]: {
                        ...c,
                        events: c.events.map((e) =>
                            e.caseActivityId === action.caseActivityId
                                ? {
                                      ...e,
                                      text: action.text,
                                      duration: e.duration + action.duration,
                                      activityDescription: action.description,
                                  }
                                : e
                        ),
                        totalActivityTime: c.totalActivityTime + action.duration,
                    },
                },
            };
        }

        case types.SET_INTERNAL_NOTE_CASE_ACTIVITY_ID: {
            let c = state.cases[action.caseId];

            return {
                ...state,
                cases: {
                    ...state.cases,
                    [action.caseId]: {
                        ...c,
                        internalNote: {
                            ...c.internalNote,
                            caseActivityId: action.caseActivityId,
                        },
                    },
                },
            };
        }

        case types.SET_CONFIRM_COMPLETE_TASK_INFO: {
            return {
                ...state,
                confirmCompleteTask: {
                    open: action.isOpen,
                    info: {
                        channelType: action.channelType,
                        sid: action.sid,
                        taskSid: action.taskSid,
                    },
                    emailAllocated: action.isEmailAllocated,
                },
            };
        }

        case types.SET_CASE_DESCRIPTION: {
            let c = state.cases[action.caseId];

            return {
                ...state,
                cases: {
                    ...state.cases,
                    [action.caseId]: {
                        ...c,
                        caseSummary: {
                            ...c.caseSummary,
                            description: action.description,
                        },
                    },
                },
            };
        }

        case types.SET_CALL_NOTE_MODE: {
            let c = state.cases[action.caseId];

            return {
                ...state,
                cases: {
                    ...state.cases,
                    [action.caseId]: {
                        ...c,
                        callNote: {
                            ...c.callNote,
                            mode: action.mode,
                        },
                    },
                },
            };
        }

        case types.SET_CALL_NOTE_CONTACT: {
            let c = state.cases[action.caseId];

            let contact = action.contact;
            if (contact) {
                let numbers = [];
                if (contact.phone) numbers.push(contact.phone);
                if (contact.mobile) numbers.push(contact.mobile);

                contact.numbers = numbers;
                contact.toIndex = 0;
            }

            return {
                ...state,
                cases: {
                    ...state.cases,
                    [action.caseId]: {
                        ...c,
                        callNote: {
                            ...c.callNote,
                            contact: contact,
                        },
                    },
                },
            };
        }

        case types.SET_CALL_NOTE_OUTBOUND: {
            let c = state.cases[action.caseId];

            return {
                ...state,
                cases: {
                    ...state.cases,
                    [action.caseId]: {
                        ...c,
                        callNote: {
                            ...c.callNote,
                            outbound: action.outbound,
                        },
                    },
                },
            };
        }

        case types.SET_EMAIL: {
            let c = state.cases[action.caseId];

            return {
                ...state,
                cases: {
                    ...state.cases,
                    [action.caseId]: {
                        ...c,
                        email: {
                            ...c.email,
                            email: action.email,
                        },
                    },
                },
            };
        }

        case types.SET_EMAIL_OPEN: {
            let c = state.cases[action.caseId];

            return {
                ...state,
                cases: {
                    ...state.cases,
                    [action.caseId]: {
                        ...c,
                        email: {
                            ...c.email,
                            open: action.isOpen,
                        },
                        internalNote: {
                            ...c.internalNote,
                            open: false,
                        },
                        callNote: {
                            ...c.callNote,
                            open: false,
                        },
                        miscActivity: {
                            ...c.miscActivity,
                            open: false,
                        },
                        meeting: {
                            ...c.meeting,
                            open: false,
                        },
                    },
                },
            };
        }

        case types.SET_CASE_ACTIVITY_IDS: {
            let c = state.cases[action.caseId];

            return {
                ...state,
                cases: {
                    ...state.cases,
                    [action.caseId]: {
                        ...c,
                        caseActivityIds: action.caseActivityIds,
                    },
                },
            };
        }

        case types.INCREMENT_EVENT_COUNT: {
            let c = state.cases[action.caseId];

            return {
                ...state,
                cases: {
                    ...state.cases,
                    [action.caseId]: {
                        ...c,
                        eventCount: c.eventCount + 1,
                    },
                },
            };
        }

        case types.SET_MILESTONE_VIEWER_OPEN: {
            return {
                ...state,
                milestoneOpen: action.isOpen,
            };
        }        

        case types.SET_REPLY_CC_CONTACTS: {
            let c = state.cases[action.caseId];

            let contacts = action.ccContacts || [];

            contacts.forEach((contact) => {
                let emails = [];

                if (contact.email)
                    emails.push(contact.email);

                if (contact.email2)
                    emails.push(contact.email2);

                contact.emails = emails;

                const existingContact = c.reply.ccContacts.find((x) => x.contactId === contact.contactId);

                contact.toIndex = existingContact ? existingContact.toIndex : 0;
            });

            return {
                ...state,
                cases: {
                    ...state.cases,
                    [action.caseId]: {
                        ...c,
                        reply: {
                            ...c.reply,
                            ccContacts: contacts,
                        },
                    },
                },
            };
        }

        case types.ADD_REPLY_CC: {
            let c = state.cases[action.caseId];

            return {
                ...state,
                cases: {
                    ...state.cases,
                    [action.caseId]: {
                        ...c,
                        reply: {
                            ...c.reply,
                            ccUserIds: [
                                ...c.reply.ccUserIds.filter(
                                    (id) => id !== action.userId
                                ),
                                action.userId,
                            ],
                        },
                    },
                },
            };
        }

        case types.REMOVE_REPLY_INT_CC: {
            let c = state.cases[action.caseId];

            return {
                ...state,
                cases: {
                    ...state.cases,
                    [action.caseId]: {
                        ...c,
                        reply: {
                            ...c.reply,
                            ccUserIds: c.reply.ccUserIds.filter(
                                (c) => c !== action.userId
                            ),
                        },
                    },
                },
            };
        }    

        case types.REMOVE_REPLY_CONTACT_NOT_KNOWN: {
            let c = state.cases[action.caseId];

            return {
                ...state,
                cases: {
                    ...state.cases,
                    [action.caseId]: {
                        ...c,
                        reply: {
                            ...c.reply,
                            contactsNotKnown: c.reply.contactsNotKnown.filter(
                                (email) => email !== action.email
                            ),
                        },
                    },
                },
            };
        }

        case types.ADD_REPLY_CONTACT_NOT_KNOWN: {
            let c = state.cases[action.caseId];

            return {
                ...state,
                cases: {
                    ...state.cases,
                    [action.caseId]: {
                        ...c,
                        reply: {
                            ...c.reply,
                            contactsNotKnown: !c.reply.contactsNotKnown
                                ? [action.email]
                                : [...c.reply.contactsNotKnown, action.email],
                        },
                    },
                },
            };
        }

        case types.REMOVE_REPLY_CC_CONTACT_NOT_KNOWN: {
            let c = state.cases[action.caseId];

            return {
                ...state,
                cases: {
                    ...state.cases,
                    [action.caseId]: {
                        ...c,
                        reply: {
                            ...c.reply,
                            ccNonContacts:
                                c.reply.ccNonContacts.filter(
                                    (email) => email !== action.email
                                ),
                        },
                    },
                },
            };
        }

        case types.ADD_REPLY_NON_CONTACT_CC: {
            let c = state.cases[action.caseId];

            return {
                ...state,
                cases: {
                    ...state.cases,
                    [action.caseId]: {
                        ...c,
                        reply: {
                            ...c.reply,
                            ccNonContacts: c.reply
                                .ccNonContacts
                                ? [
                                      ...c.reply.ccNonContacts,
                                      action.email,
                                  ]
                                : [action.email],
                        },
                    },
                },
            };
        }

        case types.SET_REPLY_BCC_CONTACTS: {
            let c = state.cases[action.caseId];

            let contacts = action.bccContacts;

            contacts.forEach((contact) => {
                let emails = [];
                if (contact.email) emails.push(contact.email);
                if (contact.email2) emails.push(contact.email2);

                contact.emails = emails;

                const existingContact = c.reply.bccContacts.find((x) => x.contactId === contact.contactId);

                contact.toIndex = existingContact ? existingContact.toIndex : 0;
            });

            return {
                ...state,
                cases: {
                    ...state.cases,
                    [action.caseId]: {
                        ...c,
                        reply: {
                            ...c.reply,
                            bccContacts: contacts,
                        },
                    },
                },
            };
        }

        case types.ADD_REPLY_BCC: {
            let c = state.cases[action.caseId];

            return {
                ...state,
                cases: {
                    ...state.cases,
                    [action.caseId]: {
                        ...c,
                        reply: {
                            ...c.reply,
                            bccUserIds: [
                                ...c.reply.bccUserIds.filter(
                                    (id) => id !== action.userId
                                ),
                                action.userId,
                            ],
                        },
                    },
                },
            };
        }

        case types.REMOVE_REPLY_BCC: {
            let c = state.cases[action.caseId];

            return {
                ...state,
                cases: {
                    ...state.cases,
                    [action.caseId]: {
                        ...c,
                        reply: {
                            ...c.reply,
                            bccUserIds: c.reply.bccUserIds.filter(
                                (c) => c !== action.userId
                            ),
                        },
                    },
                },
            };
        }

        case types.ADD_REPLY_NON_CONTACT_BCC: {
            let c = state.cases[action.caseId];

            return {
                ...state,
                cases: {
                    ...state.cases,
                    [action.caseId]: {
                        ...c,
                        reply: {
                            ...c.reply,
                            bccNonContacts: c.reply
                                .bccNonContacts
                                ? [
                                      ...c.reply.bccNonContacts,
                                      action.email,
                                  ]
                                : [action.email],
                        },
                    },
                },
            };
        }

        case types.REMOVE_REPLY_NON_CONTACT_BCC: {
            let c = state.cases[action.caseId];

            return {
                ...state,
                cases: {
                    ...state.cases,
                    [action.caseId]: {
                        ...c,
                        reply: {
                            ...c.reply,
                            bccNonContacts:
                                c.reply.bccNonContacts.filter(
                                    (email) => email !== action.email
                                ),
                        },
                    },
                },
            };
        }

        case types.SET_REPLY_TO_INDEX: {
            let c = state.cases[action.caseId];

            return {
                ...state,
                cases: {
                    ...state.cases,
                    [action.caseId]: {
                        ...c,
                        reply: {
                            ...c.reply,
                            contacts: c.reply.contacts.map((contact) =>
                                contact.contactId === action.contactId
                                    ? {
                                          ...contact,
                                          toIndex: action.index,
                                      }
                                    : contact
                            ),
                        },
                    },
                },
            };
        }

        case types.SET_CALL_CONTACT_TO_INDEX: {
            let c = state.cases[action.caseId];

            return {
                ...state,
                cases: {
                    ...state.cases,
                    [action.caseId]: {
                        ...c,
                        callNote: {
                            ...c.callNote,
                            contact: {
                                ...c.callNote.contact,
                                toIndex: action.index,
                            },
                        },
                    },
                },
            };
        }
        case types.SET_CASE_MINUTES: {
            let c = state.cases[action.caseId];
            if (c)
                return {
                    ...state,
                    cases: {
                        ...state.cases,
                        [action.caseId]: {
                            ...c,
                            minutes: action.minutes,
                        },
                    },
                };

            return state;
        }

        case types.REASSIGN_CASE_ACTIVITY: {
            const oldCase = state.cases[action.oldCaseId];
            const newCase = state.cases[action.newCaseId];
            const activity = oldCase.events.find((e) => e.caseActivityId === action.caseActivityId);

            const oldCaseFiltered = {
                ...oldCase,
                caseSummary: {
                    ...oldCase.caseSummary,
                    totalChargeableActivityTimeInSeconds: oldCase.caseSummary.totalChargeableActivityTimeInSeconds - activity.duration,
                },
                events: oldCase.events.filter(
                    (e) => e.caseActivityId !== action.caseActivityId
                ),
                eventCount: oldCase.eventCount - 1,
                totalActivityTime: oldCase.totalActivityTime - activity.duration,
            };

            const oldestNewCaseEvent =
                newCase.events[newCase.events.length - 1];
            let newCaseModified = newCase;

            if (
                !oldestNewCaseEvent ||
                (oldestNewCaseEvent &&
                    new Date(activity.eventTime) >
                        new Date(oldestNewCaseEvent.eventTime))
            )
                newCaseModified = {
                    ...newCase,
                    events: [...newCase.events, activity].sort(
                        (a, b) => new Date(b.eventTime) - new Date(a.eventTime)
                    ),
                };

            newCaseModified = {
                ...newCaseModified,
                caseSummary: {
                    ...newCaseModified.caseSummary,
                    totalChargeableActivityTimeInSeconds:
                        newCase.caseSummary
                            .totalChargeableActivityTimeInSeconds +
                        activity.duration,
                },
                eventCount: newCaseModified.eventCount + 1,
                totalActivityTime:
                    newCaseModified.totalActivityTime + activity.duration,
            };

            return {
                ...state,
                cases: {
                    ...state.cases,
                    [action.oldCaseId]: oldCaseFiltered,
                    [action.newCaseId]: newCaseModified,
                },
            };
        }

        case types.SET_MISC_ACTIVITY_OPEN: {
            let c = state.cases[action.caseId];

            return {
                ...state,
                cases: {
                    ...state.cases,
                    [action.caseId]: {
                        ...c,
                        miscActivity: {
                            ...c.miscActivity,
                            open: action.isOpen,
                        },
                        meeting: {
                            ...c.meeting,
                            open: false,
                        },
                        email: {
                            ...c.email,
                            open: false,
                        },
                        internalNote: {
                            ...c.internalNote,
                            open: false,
                        },
                        callNote: {
                            ...c.callNote,
                            open: false,
                        },
                    },
                },
            };
        }

        case types.SET_MEETING_OPEN: {
            let c = state.cases[action.caseId];

            return {
                ...state,
                cases: {
                    ...state.cases,
                    [action.caseId]: {
                        ...c,
                        meeting: {
                            ...c.meeting,
                            open: action.isOpen,
                        },
                        miscActivity: {
                            ...c.miscActivity,
                            open: false,
                        },
                        email: {
                            ...c.email,
                            open: false,
                        },
                        internalNote: {
                            ...c.internalNote,
                            open: false,
                        },
                        callNote: {
                            ...c.callNote,
                            open: false,
                        },
                    },
                },
            };
        }

        case types.SET_MEETING_FIELD: {
            const c = state.cases[action.caseId];

            return {
                ...state,
                cases: {
                    ...state.cases,
                    [action.caseId]: {
                        ...c,
                        meeting: {
                            ...c.meeting,
                            [action.field]: action.value
                        },
                    },
                },
            };
        }


        case types.SET_MISC_CASE_ACTIVITY_ID: {
            let c = state.cases[action.caseId];

            return {
                ...state,
                cases: {
                    ...state.cases,
                    [action.caseId]: {
                        ...c,
                        miscActivity: {
                            ...c.miscActivity,
                            current: {
                                ...c.miscActivity.current,
                                caseActivityId:
                                    action.group === miscActivityGroups.CURRENT
                                        ? action.caseActivityId
                                        : c.miscActivity.current.caseActivityId,
                            },
                            retrospective: {
                                ...c.miscActivity.retrospective,
                                caseActivityId:
                                    action.group ===
                                        miscActivityGroups.RETROSPECTIVE ||
                                    action.group === miscActivityGroups.DRAFTING
                                        ? action.caseActivityId
                                        : c.miscActivity.retrospective
                                              .caseActivityId,
                            },
                        },
                    },
                },
            };
        }

        case types.SET_MISC_ACTIVITY_DESCRIPTION: {
            const c = state.cases[action.caseId];

            return {
                ...state,
                cases: {
                    ...state.cases,
                    [action.caseId]: {
                        ...c,
                        miscActivity: {
                            ...c.miscActivity,
                            current: {
                                ...c.miscActivity.current,
                                description:
                                    action.group === miscActivityGroups.CURRENT
                                        ? action.description
                                        : c.miscActivity.current.description,
                            },
                            retrospective: {
                                ...c.miscActivity.retrospective,
                                description:
                                    action.group ===
                                        miscActivityGroups.RETROSPECTIVE ||
                                    action.group === miscActivityGroups.DRAFTING
                                        ? action.description
                                        : c.miscActivity.retrospective
                                              .description,
                            },
                        },
                    },
                },
            };
        }

        case types.SET_MISC_ACTIVITY_NOTES: {
            let c = state.cases[action.caseId];

            return {
                ...state,
                cases: {
                    ...state.cases,
                    [action.caseId]: {
                        ...c,
                        miscActivity: {
                            ...c.miscActivity,
                            current: {
                                ...c.miscActivity.current,
                                notes:
                                    action.group === miscActivityGroups.CURRENT
                                        ? action.notes
                                        : c.miscActivity.current.notes,
                            },
                            retrospective: {
                                ...c.miscActivity.retrospective,
                                notes:
                                    action.group ===
                                        miscActivityGroups.RETROSPECTIVE ||
                                    action.group === miscActivityGroups.DRAFTING
                                        ? action.notes
                                        : c.miscActivity.retrospective.notes,
                            },
                        },
                    },
                },
            };
        }

        case types.SET_MISC_ACTIVITY_TYPE_ID: {
            let c = state.cases[action.caseId];

            return {
                ...state,
                cases: {
                    ...state.cases,
                    [action.caseId]: {
                        ...c,
                        miscActivity: {
                            ...c.miscActivity,
                            current: {
                                ...c.miscActivity.current,
                                miscActivityTypeId:
                                    action.group === miscActivityGroups.CURRENT
                                        ? action.miscActivityTypeId
                                        : c.miscActivity.current
                                              .miscActivityTypeId,
                            },
                            retrospective: {
                                ...c.miscActivity.retrospective,
                                miscActivityTypeId:
                                    action.group ===
                                        miscActivityGroups.RETROSPECTIVE ||
                                    action.group === miscActivityGroups.DRAFTING
                                        ? action.miscActivityTypeId
                                        : c.miscActivity.retrospective
                                              .miscActivityTypeId,
                            },
                        },
                    },
                },
            };
        }

        case types.SET_MISC_ACTIVITY_DATE: {
            let c = state.cases[action.caseId];

            console.log({date: action.date});

            return {
                ...state,
                cases: {
                    ...state.cases,
                    [action.caseId]: {
                        ...c,
                        miscActivity: {
                            ...c.miscActivity,
                            retrospective: {
                                ...c.miscActivity.retrospective,
                                date: action.date,
                            },
                        },
                    },
                },
            };
        }

        case types.SET_MISC_ACTIVITY_START_TIME: {
            let c = state.cases[action.caseId];

            console.log({ startTime: action.date });            

            return {
                ...state,
                cases: {
                    ...state.cases,
                    [action.caseId]: {
                        ...c,
                        miscActivity: {
                            ...c.miscActivity,
                            retrospective: {
                                ...c.miscActivity.retrospective,
                                startTime: action.date,
                            },
                        },
                    },
                },
            };
        }

        case types.SET_MISC_ACTIVITY_HOURS: {
            let c = state.cases[action.caseId];

            return {
                ...state,
                cases: {
                    ...state.cases,
                    [action.caseId]: {
                        ...c,
                        miscActivity: {
                            ...c.miscActivity,
                            retrospective: {
                                ...c.miscActivity.retrospective,
                                hours: action.hours,
                            },
                        },
                    },
                },
            };
        }

        case types.SET_MISC_ACTIVITY_MINUTES: {
            let c = state.cases[action.caseId];

            return {
                ...state,
                cases: {
                    ...state.cases,
                    [action.caseId]: {
                        ...c,
                        miscActivity: {
                            ...c.miscActivity,
                            retrospective: {
                                ...c.miscActivity.retrospective,
                                minutes: action.minutes,
                            },
                        },
                    },
                },
            };
        }

        case types.UPDATE_MEETING_ACTIVITY: {
            let c = state.cases[action.caseId];
            let a = action.activity;

            return {
                ...state,
                cases: {
                    ...state.cases,
                    [action.caseId]: {
                        ...c,
                        events: c.events.map((e) =>
                            e.caseActivityId === a.caseActivityId
                                ? {
                                    ...e,
                                    eventTime: a.eventTime,
                                    duration: a.duration,
                                    content: a.content,
                                    activityDescription: a.activityDescription,
                                }
                                : e
                        ),
                    },
                },
            };
        }

        case types.UPDATE_RETROSPECTIVE_MISC_ACTIVITY: {
            let c = state.cases[action.caseId];
            const a = action.activity;
            return {
                ...state,
                cases: {
                    ...state.cases,
                    [action.caseId]: {
                        ...c,
                        events: c.events.map((e) =>
                            e.caseActivityId === a.caseActivityId
                                ? {
                                      ...e,
                                      eventTime: a.eventTime,
                                      duration: a.duration,
                                      content: a.content,
                                      miscActivityTypeId: a.miscActivityTypeId,
                                      attachments: a.attachments,
                                      activityDescription: a.activityDescription,
                                  }
                                : e
                        ),
                    },
                },
            };
        }

        case types.UPDATE_CURRENT_MISC_ACTIVITY: {
            let c = state.cases[action.caseId];
            const a = action.activity;
            return {
                ...state,
                cases: {
                    ...state.cases,
                    [action.caseId]: {
                        ...c,
                        events: c.events.map((e) =>
                            e.caseActivityId === a.caseActivityId
                                ? {
                                      ...e,
                                      content: a.content,
                                      miscActivityTypeId: a.miscActivityTypeId,
                                      attachments: a.attachments,
                                      activityDescription:
                                          a.activityDescription,
                                      duration: e.duration + a.duration,
                                  }
                                : e
                        ),
                    },
                },
            };
        }

        case types.SET_EXISTING_MISC_ACTIVITY_FILES: {
            let c = state.cases[action.caseId];

            switch (action.group) {
                case miscActivityGroups.CURRENT:
                    c.miscActivity.current.existingFiles = action.files;
                    break;
                case miscActivityGroups.RETROSPECTIVE:
                case miscActivityGroups.DRAFTING:
                    c.miscActivity.retrospective.existingFiles = action.files;
                    break;
                default:
                    break;
            }

            return {
                ...state,
                cases: {
                    ...state.cases,
                    [action.caseId]: c,
                },
            };
        }

        case types.SET_NEW_MISC_ACTIVITY_FILES: {
            let c = state.cases[action.caseId];

            switch (action.group) {
                case miscActivityGroups.CURRENT:
                    return {
                        ...state,
                        cases: {
                            ...state.cases,
                            [action.caseId]: {
                                ...c,
                                miscActivity: {
                                    ...c.miscActivity,
                                    current: {
                                        ...c.miscActivity.retrospective,
                                        newFiles: action.files,
                                    },
                                },
                            },
                        },
                    };
                case miscActivityGroups.RETROSPECTIVE:
                case miscActivityGroups.DRAFTING:
                    return {
                        ...state,
                        cases: {
                            ...state.cases,
                            [action.caseId]: {
                                ...c,
                                miscActivity: {
                                    ...c.miscActivity,
                                    retrospective: {
                                        ...c.miscActivity.retrospective,
                                        newFiles: action.files,
                                    },
                                },
                            },
                        },
                    };
                default:
                    return state;
            }
        }

        case types.SET_MISC_ACTIVITY_FILES_TO_DELETE: {
            let c = state.cases[action.caseId];

            switch (action.group) {
                case miscActivityGroups.CURRENT:
                    c.miscActivity.current.filesToDelete = action.references;
                    break;
                case miscActivityGroups.RETROSPECTIVE:
                case miscActivityGroups.DRAFTING:
                    c.miscActivity.retrospective.filesToDelete = action.references;
                    break;
                default:
                    break;
            }

            return {
                ...state,
                cases: {
                    ...state.cases,
                    [action.caseId]: c,
                },
            };
        }

        case types.SET_REPLYING_TO: {
            let c = state.cases[state.currentCaseId];

            return {
                ...state,
                cases: {
                    ...state.cases,
                    [state.currentCaseId]: {
                        ...c,
                        reply: {
                            ...c.reply,
                            replyingTo: action.replyingTo,
                        },
                    },
                },
            };
        }

        case types.SET_EDITING_REPLY_CHAIN: {
            let c = state.cases[state.currentCaseId];

            return {
                ...state,
                cases: {
                    ...state.cases,
                    [state.currentCaseId]: {
                        ...c,
                        reply: {
                            ...c.reply,
                            editingReplyChain: action.editingReplyChain,
                        },
                    },
                },
            };
        }

        case types.SET_ADJUST_CASE_MINUTES: {
            let c = state.cases[action.caseId];
            if (c)
                return {
                    ...state,
                    cases: {
                        ...state.cases,
                        [action.caseId]: {
                            ...c,
                            adjustMinutes: action.minutes,
                        },
                    },
                };

            return state;
        }

        case types.SET_EMAILS: {
            let c = state.cases[action.caseId];

            return {
                ...state,
                cases: {
                    ...state.cases,
                    [action.caseId]: {
                        ...c,
                        emails: action.emails,
                    },
                },
            };
        }

        case types.UPDATE_CASE_DRAFT_EMAIL: {
            let c = state.cases[action.payload.caseId];

            return {
                ...state,
                cases: {
                    ...state.cases,
                    [action.payload.caseId]: {
                        ...c,
                        draftEmail: action.payload,
                    },
                },
            };
        }

        case types.CLEAR_CASE_DRAFT_EMAIL: {
            let c = state.cases[action.payload.caseId];

            return {
                ...state,
                cases: {
                    ...state.cases,
                    [action.payload.caseId]: {
                        ...c,
                        draftEmail: null,
                    },
                },
            };
        }

        case types.SET_EDITING_DRAFT: {
            let c = state.cases[action.payload.caseId];

            return {
                ...state,
                cases: {
                    ...state.cases,
                    [action.payload.caseId]: {
                        ...c,
                        reply: {
                            ...c.reply,
                            isEditingDraft: action.payload.isEditingDraft,
                        },
                    },
                },
            };
        }

        case types.SET_BACKDROP_OPEN: {
            return {
                ...state,
                backdropOpen: action.isOpen,
            };
        }

        case types.SET_CASE_ACTIVITY_DURATION: {
            let c = state.cases[action.caseId];
            const event = c.events.find((e) => e.caseActivityId === action.caseActivityId);

            if (!event) return;

            const oldDuration = event.duration;

            return {
                ...state,
                cases: {
                    ...state.cases,
                    [action.caseId]: {
                        ...c,
                        events: c.events.map((e) =>
                            e.caseActivityId === action.caseActivityId
                                ? {
                                      ...e,
                                      duration: action.duration,
                                  }
                                : e
                        ),
                        totalActivityTime:
                            c.totalActivityTime +
                            (action.duration - oldDuration),
                    },
                },
            };
        }

        case types.SET_SHOW_EDITOR: {
            return {
                ...state,
                showEditor: action.payload.showEditor,
            };
        }

        case types.SET_CASE_HISTORY_MODE: {
            let c = state.cases[action.caseId];

            return {
                ...state,
                cases: {
                    ...state.cases,
                    [action.caseId]: {
                        ...c,
                        caseHistory: {
                            ...c.caseHistory,
                            mode: action.mode,
                        },
                    },
                },
            };
        }

        case types.SET_CASE_HISTORY_CHECK_ALL: {
            let c = state.cases[action.caseId];

            return {
                ...state,
                cases: {
                    ...state.cases,
                    [action.caseId]: {
                        ...c,
                        caseHistory: {
                            ...c.caseHistory,
                            selectAllChecked: action.checked,
                        },
                    },
                },
            };
        }

        case types.SET_DOWNLOAD_CASE_ACTIVITY_IDS: {
            let c = state.cases[action.caseId];

            return {
                ...state,
                cases: {
                    ...state.cases,
                    [action.caseId]: {
                        ...c,
                        caseHistory: {
                            ...c.caseHistory,
                            downloadCaseActivityIds: action.ids,
                        },
                    },
                },
            };
        }

        case types.SET_CASE_ATTACHMENTS_SELECTED: {
            let c = state.cases[action.caseId];
            return {
                ...state,
                cases: {
                    ...state.cases,
                    [action.caseId]: {
                        ...c,
                        reply: {
                            ...c.reply,
                            caseAttachmentsSelected: action.attachments,
                        },
                    },
                },
            };
        }

        case types.SET_DRAFT_ATTACHMENTS_SELECTED: {
            let c = state.cases[action.caseId];
            return {
                ...state,
                cases: {
                    ...state.cases,
                    [action.caseId]: {
                        ...c,
                        reply: {
                            ...c.reply,
                            draftAttachmentsSelected: action.attachments,
                        },
                    },
                },
            };
        }

        case types.SET_CASE_ACTIVITY_DESCRIPTION: {
            let c = state.cases[action.caseId];
            const event = c.events.find((e) => e.caseActivityId === action.caseActivityId);

            if (!event)
                return;

            return {
                ...state,
                cases: {
                    ...state.cases,
                    [action.caseId]: {
                        ...c,
                        events: c.events.map((e) =>
                            e.caseActivityId === action.caseActivityId
                                ? {
                                      ...e,
                                      activityDescription:
                                          action.activityDescription,
                                  }
                                : e
                        ),
                    },
                },
            };
        }

        case types.SET_CASE_RESEARCH_OPEN: {
            return {
                ...state,
                caseResearch: {
                    ...state.caseResearch,
                    open: action.isOpen,
                },
            };
        }

        case types.SET_CASE_FILES_TREE_CHANGED: {
            let c = state.cases[action.caseId];
            return {
                ...state,
                cases: {
                    ...state.cases,
                    [action.caseId]: {
                        ...c,
                        caseFilesTreeChanged: action.hasChanged,
                    },
                },
            };
        }

        case types.SET_CASE_RESEARCH_SESSION_ID: {
            return {
                ...state,
                caseResearch: {
                    ...state.caseResearch,
                    sessionId: action.id,
                },
            };
        }

        case types.SET_CONFIRM_CLOSE_OPEN: {
            return {
                ...state,
                confirmCloseOpen: action.isOpen,
            };
        }

        case types.SET_EXISTING_CASE_RESEARCH_SESSION: {
            return {
                ...state,
                caseResearch: {
                    ...state.caseResearch,
                    existingSession: action.isExistingSession,
                },
            };
        }

        case types.SET_CASE_RESEARCH_START: {
            return {
                ...state,
                caseResearch: {
                    ...state.caseResearch,
                    start: action.start,
                },
            };
        }

        case types.SET_CASE_CHARGEABLE: {
            const c = state.cases[action.caseId];

            return {
                ...state,
                cases: {
                    ...state.cases,
                    [action.caseId]: {
                        ...c,
                        caseSummary: {
                            ...c.caseSummary,
                            isChargeable: action.isChargeable,
                        },
                    },
                },
            };
        }

        case types.UPDATE_CASE_CHARGEABLE_TIME: {
            const c = state.cases[action.caseId];
            const newChargeableTime =
                c.caseSummary.totalChargeableActivityTimeInSeconds +
                action.delta;

            return {
                ...state,
                cases: {
                    ...state.cases,
                    [action.caseId]: {
                        ...c,
                        caseSummary: {
                            ...c.caseSummary,
                            totalChargeableActivityTimeInSeconds:
                                newChargeableTime,
                        },
                    },
                },
            };
        }

        case types.SEND_EMAIL_REQUEST: {
            const c = state.cases[state.currentCaseId];

            return {
                ...state,
                cases: {
                    ...state.cases,
                    [state.currentCaseId]: {
                        ...c,
                        reply: {
                            ...c.reply,
                            sending: true,
                        },
                    },
                },
            };
        }

        case types.SEND_EMAIL_SUCCESS:
        case types.SEND_EMAIL_FAIL: {
            const c = state.cases[state.currentCaseId];

            return {
                ...state,
                cases: {
                    ...state.cases,
                    [state.currentCaseId]: {
                        ...c,
                        reply: {
                            ...c.reply,
                            sending: false,
                        },
                    },
                },
            };
        }

        case types.ADD_CALL_AND_NOTE_REQUEST:
        case types.ADD_CALL_REQUEST:
        case types.UPDATE_NOTE_REQUEST: {
            const c = state.cases[state.currentCaseId];

            return {
                ...state,
                cases: {
                    ...state.cases,
                    [state.currentCaseId]: {
                        ...c,
                        callNote: {
                            ...c.callNote,
                            saving: true,
                        },
                    },
                },
            };
        }

        case types.ADD_CALL_AND_NOTE_SUCCESS:
        case types.ADD_CALL_AND_NOTE_FAIL:
        case types.ADD_CALL_SUCCESS:
        case types.ADD_CALL_FAIL:
        case types.UPDATE_NOTE_SUCCESS:
        case types.UPDATE_NOTE_FAIL: {
            const c = state.cases[state.currentCaseId];

            return {
                ...state,
                cases: {
                    ...state.cases,
                    [state.currentCaseId]: {
                        ...c,
                        callNote: {
                            ...c.callNote,
                            saving: false,
                        },
                    },
                },
            };
        }

        case types.SAVE_INTERNAL_NOTE_REQUEST: {
            const c = state.cases[state.currentCaseId];

            return {
                ...state,
                cases: {
                    ...state.cases,
                    [state.currentCaseId]: {
                        ...c,
                        internalNote: {
                            ...c.internalNote,
                            saving: true,
                        },
                    },
                },
            };
        }

        case types.SAVE_INTERNAL_NOTE_FAIL:
        case types.SAVE_INTERNAL_NOTE_SUCCESS: {
            const c = state.cases[state.currentCaseId];

            return {
                ...state,
                cases: {
                    ...state.cases,
                    [state.currentCaseId]: {
                        ...c,
                        internalNote: {
                            ...c.internalNote,
                            saving: false,
                        },
                    },
                },
            };
        }

        case types.SAVE_DRAFT_REQUEST: {
            const c = state.cases[state.currentCaseId];

            return {
                ...state,
                cases: {
                    ...state.cases,
                    [state.currentCaseId]: {
                        ...c,
                        reply: {
                            ...c.reply,
                            savingDraft: true,
                        },
                    },
                },
            };
        }

        case types.SAVE_DRAFT_SUCCESS:
        case types.SAVE_DRAFT_FAIL: {
            const c = state.cases[state.currentCaseId];

            return {
                ...state,
                cases: {
                    ...state.cases,
                    [state.currentCaseId]: {
                        ...c,
                        reply: {
                            ...c.reply,
                            savingDraft: false,
                        },
                    },
                },
            };
        }

        case types.DISCARD_UNALLOCATED_TIME_REQUEST:
        case types.ASSIGN_UNALLOCATED_TIME_TO_USER_ACTIVITY_REQUEST:
        case types.ASSIGN_UNALLOCATED_TIME_TO_MISC_ACTIVITY_REQUEST:
        case types.HOLD_UNALLOCATED_TIME_REQUEST: {
            return {
                ...state,
                processUnallocatedTimeRequestStatus: requestStatus.PENDING,
            };
        }

        case types.DISCARD_UNALLOCATED_TIME_SUCCESS:
        case types.ASSIGN_UNALLOCATED_TIME_TO_USER_ACTIVITY_SUCCESS:
        case types.ASSIGN_UNALLOCATED_TIME_TO_MISC_ACTIVITY_SUCCESS:
        case types.HOLD_UNALLOCATED_TIME_SUCCESS: {
            return {
                ...state,
                processUnallocatedTimeRequestStatus: requestStatus.RESOLVED,
            };
        }

        case types.DISCARD_UNALLOCATED_TIME_FAIL:
        case types.ASSIGN_UNALLOCATED_TIME_TO_USER_ACTIVITY_FAIL:
        case types.ASSIGN_UNALLOCATED_TIME_TO_MISC_ACTIVITY_FAIL:
        case types.HOLD_UNALLOCATED_TIME_FAIL: {
            return {
                ...state,
                processUnallocatedTimeRequestStatus: requestStatus.REJECTED,
            };
        }

        case types.SET_PROCESS_UNALLOCATED_TIME_REQUEST_STATUS: {
            return {
                ...state,
                processUnallocatedTimeRequestStatus:
                    action.payload.requestStatus,
            };
        }

        case types.SET_GET_UNALLOCATED_TIME_REQUEST_STATUS: {
            return {
                ...state,
                getUnallocatedTimeRequestStatus: action.payload.requestStatus,
            };
        }

        case types.SET_UNALLOCATED_SECONDS: {
            return {
                ...state,
                unallocatedSeconds: action.payload.seconds,
            };
        }

        case types.SET_UNALLOCATED_CASE_TIME_EVENT_IDS: {
            return {
                ...state,
                unallocatedCaseTimeEventIds: action.payload.caseTimeEventIds,
            };
        }

        case types.GET_UNALLOCATED_TIME_REQUEST: {
            return {
                ...state,
                getUnallocatedTimeRequestStatus: requestStatus.PENDING,
            };
        }

        case types.GET_UNALLOCATED_TIME_SUCCESS: {
            return {
                ...state,
                getUnallocatedTimeRequestStatus: requestStatus.RESOLVED,
                unallocatedSeconds: action.payload.seconds,
                unallocatedCaseTimeEventIds: action.payload.caseTimeEventIds,
            };
        }

        case types.GET_UNALLOCATED_TIME_FAIL: {
            return {
                ...state,
                getUnallocatedTimeRequestStatus: requestStatus.REJECTED,
            };
        }

        case types.SET_CASE_VISIBLE_TO_CONTACTS_ONLY: {
            const c = state.cases[action.caseId];

            return {
                ...state,
                cases: {
                    ...state.cases,
                    [action.caseId]: {
                        ...c,
                        caseSummary: {
                            ...c.caseSummary,
                            isVisibleToContactsOnly:
                                action.isVisibleToContactsOnly,
                        },
                    },
                },
            };
        }

        case types.SET_MISC_ACTIVITY_TAB: {
            const c = state.cases[action.payload.caseId];

            return {
                ...state,
                cases: {
                    ...state.cases,
                    [action.payload.caseId]: {
                        ...c,
                        miscActivity: {
                            ...c.miscActivity,
                            tab: action.payload.tab,
                        },
                    },
                },
            };
        }

        case types.RESET_MISC_ACTIVITY: {

            const caseId = action.payload?.caseId
                ? action.payload.caseId
                : state.currentCaseId;

            const c = state.cases[caseId];

            if (c)
                return {
                    ...state,
                    currentCaseId: caseId,
                    cases: {
                        ...state.cases,
                        [caseId]: {
                            ...c,
                            miscActivity: {
                                ...emptyMiscActivity,
                                retrospective: { ...emptyRetrospectiveMiscActivity },
                                current: { ...emptyCurrentMiscActivity },
                            },
                        },
                    },
                };
            return state;
        }

        case types.RESET_MEETING: {            

            const c = state.cases[action.caseId];

            if (c)
                return {
                    ...state,
                    cases: {
                        ...state.cases,
                        [action.caseId]: {
                            ...c,
                            meeting: emptyMeeting
                        },
                    },
                };
            return state;
        }               

        case types.SET_MEETING: {
            const c = state.cases[action.caseId];

            console.log({ startTime: action.meeting.startTime });

            let minutes = differenceInMinutes(new Date(action.meeting.endTime), new Date(action.meeting.startTime));

            return {
                ...state,
                cases: {
                    ...state.cases,
                    [action.caseId]: {
                        ...c,
                        meeting: {
                            ...c.meeting,
                            meeting: action.meeting,
                            date: action.meeting.startTime,
                            startTime: action.meeting.startTime,
                            hours: Math.floor(minutes / 60),
                            minutes : minutes % 60
                        },
                    },
                },
            };
        }


        case types.ADD_CURRENT_MISC_ACTIVITY_REQUEST: {
            let c = state.cases[state.currentCaseId];
            return {
                ...state,
                cases: {
                    ...state.cases,
                    [state.currentCaseId]: {
                        ...c,
                        miscActivity: {
                            ...c.miscActivity,
                            current: {
                                ...c.miscActivity.current,
                                saving: true,
                            },
                        },
                    },
                },
            };
        }
        case types.ADD_CURRENT_MISC_ACTIVITY_SUCCESS:
        case types.ADD_CURRENT_MISC_ACTIVITY_FAIL: {
            let c = state.cases[state.currentCaseId];
            return {
                ...state,
                cases: {
                    ...state.cases,
                    [state.currentCaseId]: {
                        ...c,
                        miscActivity: {
                            ...c.miscActivity,
                            current: {
                                ...c.miscActivity.current,
                                saving: false,
                            },
                        },
                    },
                },
            };
        }

        case types.RESET_CURRENT_MISC_ACTIVITY: {
            let c = state.cases[state.currentCaseId];
            return {
                ...state,
                cases: {
                    ...state.cases,
                    [state.currentCaseId]: {
                        ...c,
                        miscActivity: {
                            ...c.miscActivity,
                            current: emptyCurrentMiscActivity,
                        },
                    },
                },
            };
        }

        case types.SET_CURRENT_MISC_ACTIVITY_OPEN: {
            let c = state.cases[state.currentCaseId];
            return {
                ...state,
                cases: {
                    ...state.cases,
                    [state.currentCaseId]: {
                        ...c,
                        miscActivity: {
                            ...c.miscActivity,
                            current: {
                                ...c.miscActivity.current,
                                open: action.payload.isOpen,
                            },
                        },
                    },
                },
            };
        }

        case types.SET_CASE_FILES_FOR_DOWNLOAD: {
            let c = state.cases[action.caseId];
            return {
                ...state,
                cases: {
                    ...state.cases,
                    [action.caseId]: {
                        ...c,
                        caseFilesForDownload: action.caseFiles,
                    },
                },
            };
        }

        case types.SET_CASE_OUTCOME_ID: {
            let c = state.cases[action.payload.caseId];
            return {
                ...state,
                cases: {
                    ...state.cases,
                    [action.payload.caseId]: {
                        ...c,
                        caseSummary: {
                            ...c.caseSummary,
                            caseOutcomeId: action.payload.caseOutcomeId,
                        },
                    },
                },
            };
        }

        case types.SET_MISC_ACTIVITY_ATTACHMENTS: {
            let c = state.cases[action.caseId];

            return {
                ...state,
                cases: {
                    ...state.cases,
                    [action.caseId]: {
                        ...c,
                        miscActivity: {
                            ...c.miscActivity,
                            attachments: action.attachments,
                        },
                    },
                },
            };
        }

        case types.SET_MISC_ACTIVITY_DRAFT_ATTACHMENTS_SELECTED: {
            let c = state.cases[action.caseId];
            return {
                ...state,
                cases: {
                    ...state.cases,
                    [action.caseId]: {
                        ...c,
                        miscActivity: {
                            ...c.miscActivity,
                            draftAttachmentsSelected: action.attachments,
                        },
                    },
                },
            };
        }        

        case types.SET_CASE_HISTORY_DATE_FROM: {
            let c = state.cases[action.caseId];
            return {
                ...state,
                cases: {
                    ...state.cases,
                    [action.caseId]: {
                        ...c,
                        historyFilters: {
                            ...c.historyFilters,
                            dateFrom: action.dateFrom,
                        },
                    },
                },
            };
        }

        case types.SET_CASE_HISTORY_DATE_TO: {
            let c = state.cases[action.caseId];
            return {
                ...state,
                cases: {
                    ...state.cases,
                    [action.caseId]: {
                        ...c,
                        historyFilters: {
                            ...c.historyFilters,
                            dateTo: action.dateTo,
                        },
                    },
                },
            };
        }

        case types.RESET_CASE_HISTORY_FILTERS: {
            let c = state.cases[action.caseId];
            return {
                ...state,
                cases: {
                    ...state.cases,
                    [action.caseId]: {
                        ...c,
                        historyFilters: emptyHistoryFilters,
                    },
                },
            };
        }

        case types.SET_CASE_HISTORY_ACTIVITY: {
            let c = state.cases[action.caseId];
            return {
                ...state,
                cases: {
                    ...state.cases,
                    [action.caseId]: {
                        ...c,
                        historyFilters: {
                            ...c.historyFilters,
                            activities: {
                                ...c.historyFilters.activities,
                                [action.activityName]: { ...action.activity },
                            },
                        },
                    },
                },
            };
        }

        case types.REMOVE_CASE_HISTORY_ACTIVITY: {
            let c = state.cases[action.caseId];

            let {
                [action.activityName]: activityToBeRemoved,
                ...remainingActivities
            } = c.historyFilters.activities;

            return {
                ...state,
                cases: {
                    ...state.cases,
                    [action.caseId]: {
                        ...c,
                        historyFilters: {
                            ...c.historyFilters,
                            activities: {
                                ...remainingActivities,
                            },
                        },
                    },
                },
            };
        }

        case types.SET_CASE_HISTORY_CHARGEABLE: {
            let c = state.cases[action.caseId];
            return {
                ...state,
                cases: {
                    ...state.cases,
                    [action.caseId]: {
                        ...c,
                        historyFilters: {
                            ...c.historyFilters,
                            chargeables: {
                                ...c.historyFilters.chargeables,
                                [action.label]: {
                                    isChargeable: action.isChargeable,
                                    label: action.label,
                                },
                            },
                        },
                    },
                },
            };
        }

        case types.REMOVE_CASE_HISTORY_CHARGEABLE: {
            let c = state.cases[action.caseId];

            let {
                [action.label]: chargeableToBeRemoved,
                ...remainingChargeables
            } = c.historyFilters.chargeables;

            return {
                ...state,
                cases: {
                    ...state.cases,
                    [action.caseId]: {
                        ...c,
                        historyFilters: {
                            ...c.historyFilters,
                            chargeables: { ...remainingChargeables },
                        },
                    },
                },
            };
        }

        case types.SET_CASE_HISTORY_DIRECTION: {
            let c = state.cases[action.caseId];
            return {
                ...state,
                cases: {
                    ...state.cases,
                    [action.caseId]: {
                        ...c,
                        historyFilters: {
                            ...c.historyFilters,
                            directions: {
                                ...c.historyFilters.directions,
                                [action.direction]: {
                                    direction: action.direction,
                                    label: action.label,
                                },
                            },
                        },
                    },
                },
            };
        }

        case types.REMOVE_CASE_HISTORY_DIRECTION: {
            let c = state.cases[action.caseId];

            let {
                [action.direction]: directionToBeRemoved,
                ...remainingDirections
            } = c.historyFilters.directions;

            return {
                ...state,
                cases: {
                    ...state.cases,
                    [action.caseId]: {
                        ...c,
                        historyFilters: {
                            ...c.historyFilters,
                            directions: { ...remainingDirections },
                        },
                    },
                },
            };
        }

        case types.SET_CASE_HISTORY_USER: {
            let c = state.cases[action.caseId];

            return {
                ...state,
                cases: {
                    ...state.cases,
                    [action.caseId]: {
                        ...c,
                        historyFilters: {
                            ...c.historyFilters,
                            users: {
                                ...c.historyFilters.users,
                                [action.userId]: {
                                    userExternalId: action.userId,
                                    label: action.name,
                                },
                            },
                        },
                    },
                },
            };
        }

        case types.REMOVE_CASE_HISTORY_USER: {
            let c = state.cases[action.caseId];

            let { [action.userId]: userToBeRemoved, ...remainingUsers } =
                c.historyFilters.users;

            return {
                ...state,
                cases: {
                    ...state.cases,
                    [action.caseId]: {
                        ...c,
                        historyFilters: {
                            ...c.historyFilters,
                            users: { ...remainingUsers },
                        },
                    },
                },
            };
        }
        
        case types.SET_MISC_ACTIVITY_UPLOAD_STATUS: {
            let c = state.cases[action.payload.caseId];

            return {
                ...state,
                cases: {
                    ...state.cases,
                    [action.payload.caseId]: {
                        ...c,
                        miscActivity: {
                            ...c.miscActivity,
                            attachments: c.miscActivity.attachments.map(x => x.id === action.payload.id ? ({
                                ...x,
                                id: action.payload.status === uploadStatus.COMPLETE ? action.payload.reference : x.id,
                                status: action.payload.status,
                            }) : x),
                        },
                    },
                },
            };
        }

        case types.SET_REPLY_UPLOAD_STATUS: {
            let c = state.cases[action.payload.caseId];

            return {
                ...state,
                cases: {
                    ...state.cases,
                    [action.payload.caseId]: {
                        ...c,
                        reply: {
                            ...c.reply,
                            attachments: c.reply.attachments.map(x => x.id === action.payload.id ? ({
                                ...x,
                                id: action.payload.status === uploadStatus.COMPLETE ? action.payload.reference : x.id,
                                status: action.payload.status,
                            }) : x),
                        },
                    },
                },
            };
        }
        
        case types.CLEAR_NON_PRECEDENT_ATTACHMENTS: {
            const c = state.cases[action.payload.caseId];
            
            return {
                ...state,
                cases: {
                    ...state.cases,
                    [action.payload.caseId]: {
                        ...c,
                        reply: {
                            ...c.reply,
                            attachments: c.reply.attachments.filter(x => x.precedentId),
                        },
                    },
                },
            };
        }

        case types.SET_CASE_AI_STATE: {
            const c = state.cases[action.caseId];
            
            return {
                ...state,
                cases: {
                    ...state.cases,
                    [action.caseId]: {
                        ...c,
                        aiState: {
                            ...c.aiState,
                            ...action.payload
                        }
                    },
                },
            };
        }        

        default:
            return state;
    }
};

export default reducer;
