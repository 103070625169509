import React from "react";
import userRoles from "../constants/userRoles";

import { Briefcase, Sliders, Users, Smile, Settings as SettingsIcon } from "react-feather";

import Dashboard from "../pages/Dashboard";
import Accounts from "../pages/Accounts";
import Case from "../pages/Case";
import Cases from "../pages/Cases";
import Activities from "../pages/Activities";
import CaseTypes from "../pages/CaseTypes";
import Contacts from "../pages/Contacts";
import CreateCase from "../pages/CreateCase";
import Precedents from "../pages/Precedents";
import MyTemplates from "../pages/MyTemplates";
import Advisers from "../pages/Advisers";
import Adviser from "../pages/Adviser";
import ETAdmin from "../pages/ETAdmin";
import Policies from "../pages/Policies";
import StageTasks from "../pages/StageTasks";
import Subscription from "../pages/Subscription";
import OOHOverride from "../pages/OOHOverride";
import Account from "../pages/Account";
import Mentions from "../pages/Mentions";
import Partnerships from "../pages/PartnershipsAdmin";
import Knowledge from "../components/knowledge/KnowledgeAdmin";
import KnowledgeArticle from "../components/knowledge/KnowledgeArticle";
import CreateContactWizard from "../pages/CreateContactWizard";
import TeamOverview from "../pages/TeamOverview";
import TwilioTasks from "../pages/TwilioTasks";
import Calls from "../pages/Calls";
import ActivityExport from "../pages/ActivityExport";
import PartnerData from "../pages/PartnerData";
import Timesheets from "../pages/Timesheets";
import ActionsDashboard from "../pages/ActionsDashboard";
import Blank from "../mui/pages/Blank";
import HeldTime from "../pages/HeldTime";
import AutomaticReplies from "../pages/AutomaticReplies";
import { EmailDraftProvider } from "../contexts/emailDraftContext";
import {FileUploadProvider} from "../contexts/fileUploadContext";
import InvoiceTool from "../pages/InvoiceTool";

const dashboardsRoutes = {
    id: "Dashboard",
    path: "/dashboard",
    icon: <Sliders />,
    containsHome: true,
    children: [
        {
            path: "/dashboard",
            name: "Dashboard",
            component: Dashboard,
        },
        {
            path: "/teamoverview",
            name: "Team Overview",
            component: TeamOverview,
            requiredRoles: [userRoles.SUPER_USER, userRoles.LEGAL_ADMIN, userRoles.LEGAL_MANAGER, userRoles.LEGAL_ADVISOR],
        },
        {
            path: "/timesheets",
            name: "Timesheets",
            component: Timesheets,
            requiredRoles: [userRoles.SUPER_USER, userRoles.LEGAL_ADMIN, userRoles.LEGAL_MANAGER, userRoles.LEGAL_ADVISOR],
        },
        {
            path: "/actionsdashboard",
            name: "Actions Dashboard",
            component: ActionsDashboard,
            requiredRoles: [userRoles.SUPER_USER, userRoles.LEGAL_ADMIN, userRoles.LEGAL_MANAGER, userRoles.LEGAL_ADVISOR],
        },
    ],
};

const caseRoutes = {
    id: "Cases",
    icon: <Briefcase />,
    children: [
        {
            path: "/",
            viewtype: "Advisor",
            name: "My Cases",
            component: Cases,
            requiredRoles: [userRoles.LEGAL_ADVISOR, userRoles.HRC_ASSOCIATE]
        },
        {
            path: "/cases",
            viewtype: "AdvisorAll",
            name: "All Cases",
            component: Cases,
            requiredRoles: [userRoles.LEGAL_ADVISOR],
        },
        {
            path: "/activities",
            name: "My Case Activity",
            component: Activities,
            requiredRoles: [userRoles.LEGAL_ADVISOR],
        },
    ],
};

const accountsRoutes = {
    id: "Accounts",
    path: "/accounts",
    icon: <Users />,
    component: Accounts,
    requiredRoles: [userRoles.SUPER_USER, userRoles.LEGAL_ADMIN, userRoles.LEGAL_MANAGER, userRoles.LEGAL_ADVISOR, userRoles.ACCOUNTS],
};

const contactsRoutes = {
    id: "Contacts",
    path: "/contacts",
    icon: <Smile />,
    component: Contacts,
    requiredRoles: [userRoles.SUPER_USER, userRoles.LEGAL_ADMIN, userRoles.LEGAL_MANAGER, userRoles.LEGAL_ADVISOR, userRoles.ACCOUNTS],
};

const administrationRoutes = {
    id: "Administration",
    path: "/admin",
    icon: <SettingsIcon />,
    requiredRoles: [userRoles.SUPER_USER, userRoles.LEGAL_ADMIN, userRoles.LEGAL_MANAGER, userRoles.LEGAL_ADVISOR],
    children: [
        {
            path: "/admin/advisers",
            name: "Advisers",
            requiredRoles: [userRoles.SUPER_USER, userRoles.LEGAL_ADMIN, userRoles.LEGAL_MANAGER],
            component: Advisers,
        },
        {
            path: "/admin/automaticreplies",
            name: "Automatic Replies",
            component: AutomaticReplies,
            requiredRoles: [userRoles.SUPER_USER, userRoles.LEGAL_ADMIN, userRoles.LEGAL_MANAGER, userRoles.LEGAL_ADVISOR],
        },
        {
            path: "/admin/casetypes",
            name: "Case Types",
            requiredRoles: [userRoles.SUPER_USER],
            component: CaseTypes,
        },
        {
            path: "/admin/etadmin",
            name: "Employment Tribunal",
            requiredRoles: [userRoles.SUPER_USER],
            component: ETAdmin,
        },
        {
            path: "/admin/knowledge",
            name: "Knowledge",
            requiredRoles: [userRoles.SUPER_USER],
            featureFlagName: "KnowledgeBank",
            component: Knowledge,
        },
        {
            path: "/admin/policies",
            name: "Policies",
            requiredRoles: [userRoles.SUPER_USER, userRoles.LEGAL_ADMIN],
            component: Policies,
        },
        {
            path: "/admin/precedents",
            name: "Precedents",
            component: Precedents,
            requiredRoles: [userRoles.SUPER_USER, userRoles.LEGAL_ADMIN, userRoles.LEGAL_MANAGER, userRoles.LEGAL_ADVISOR],
        },
        {
            path: "/admin/stagetasks",
            name: "Stage Tasks",
            requiredRoles: [userRoles.SUPER_USER, userRoles.LEGAL_ADMIN],
            component: StageTasks,
        },
        {
            path: "/admin/subscription",
            name: "Subscription",
            requiredRoles: [userRoles.SUPER_USER],
            component: Subscription,
        },
        {
            path: "/admin/oohoverride",
            name: "OOH Override",
            requiredRoles: [userRoles.SUPER_USER],
            component: OOHOverride,
        },
        {
            path: "/admin/mentions",
            name: "Mentions",
            requiredRoles: [userRoles.SUPER_USER, userRoles.LEGAL_ADMIN],
            component: Mentions,
        },
        {
            path: "/admin/partnerships",
            name: "Partnerships",
            requiredRoles: [userRoles.SUPER_USER, userRoles.LEGAL_ADMIN],
            component: Partnerships,
        },
        {
            path: "/admin/twiliotasks",
            name: "Twilio Tasks",
            component: TwilioTasks,
            requiredRoles: [userRoles.SUPER_USER, userRoles.LEGAL_ADMIN, userRoles.LEGAL_MANAGER, userRoles.LEGAL_ADVISOR],
        },
        {
            path: "/admin/calls",
            name: "Calls",
            component: Calls,
            requiredRoles: [userRoles.SUPER_USER, userRoles.LEGAL_MANAGER],
        },
        {
            path: "/admin/activityexport",
            name: "Activity Export",
            component: ActivityExport,
            requiredRoles: [userRoles.SUPER_USER, userRoles.LEGAL_ADMIN, userRoles.LEGAL_MANAGER, userRoles.LEGAL_ADVISOR],
        },
        {
            path: "/admin/invoicetool",
            name: "Invoice Tool",
            requiredRoles: [userRoles.SUPER_USER],
            component: InvoiceTool,
        },
        {
            path: "/admin/PartnerData",
            name: "Partner Data",
            requiredRoles: [userRoles.SUPER_USER],
            component: PartnerData,
        },
        {
            path: "/user/Templates",
            name: "My Templates",
            component: MyTemplates,
            requiredRoles: [userRoles.SUPER_USER, userRoles.LEGAL_ADMIN, userRoles.LEGAL_MANAGER, userRoles.LEGAL_ADVISOR],
        },
    ],
};

const CaseWrapper = () => {
    return (
        <EmailDraftProvider>
            <FileUploadProvider>
                <Case />
            </FileUploadProvider>
        </EmailDraftProvider>
    );
};

// This route is not visible in the sidebar
const privateRoutes = {
    id: "Private",
    path: "/private",
    component: Blank,
    children: [
        {
            path: "/case",
            name: "Case",
            component: CaseWrapper,
            requiredRoles: [userRoles.LEGAL_ADVISOR, userRoles.HRC_ASSOCIATE],
        },
        {
            path: "/createcase/:accountId?/:contactId?",
            name: "CreateCase",
            component: CreateCase,
            requiredRoles: [userRoles.LEGAL_ADVISOR],
        },
        {
            path: "/user/templates",
            name: "MyTemplates",
            component: MyTemplates,
            requiredRoles: [userRoles.LEGAL_ADVISOR],
        },
        {
            path: "/adviser/:userId",
            name: "Adviser",
            component: Adviser,
            requiredRoles: [userRoles.SUPER_USER, userRoles.LEGAL_ADMIN, userRoles.LEGAL_MANAGER],
        },
        {
            path: "/account",
            name: "Account",
            component: Account,
            requiredRoles: [userRoles.SUPER_USER, userRoles.LEGAL_ADMIN, userRoles.LEGAL_MANAGER, userRoles.LEGAL_ADVISOR, userRoles.ACCOUNTS],
        },
        {
            path: "/createcontact",
            name: "Create Contact",
            component: CreateContactWizard,
            requiredRoles: [userRoles.LEGAL_ADVISOR],
        },
        {
            path: "/heldtime",
            name: "Held Time",
            component: HeldTime,
        },
        {
            path: "/admin/article/:articleId",
            name: "KnowledgeArticle",
            requiredRoles: [userRoles.SUPER_USER],
            component: KnowledgeArticle,
        },
    ],
};

// routes with dashboard layout
export const dashboard = [dashboardsRoutes, caseRoutes, accountsRoutes, contactsRoutes, administrationRoutes, privateRoutes];

// routes with dashboard layout
export const adminDashboard = [dashboardsRoutes, accountsRoutes, administrationRoutes, privateRoutes];

// visible Menu
const visibleItems = [dashboardsRoutes, caseRoutes, accountsRoutes, contactsRoutes, administrationRoutes];
export default visibleItems;
