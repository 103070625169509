import React, { useState, useRef, useMemo, useCallback, useEffect } from "react";
import {
    Box,
    Button,
    Checkbox,
    CircularProgress,
    FormControl,
    FormLabel,
    FormControlLabel,
    Grid,
    Paper,
    Radio,
    RadioGroup,
    TextField,
    Typography,
} from "@material-ui/core";
import { CSVLink } from "react-csv";
import { format } from "date-fns";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { makeStyles } from "@material-ui/core/styles"
import DividerWithCaption from "../components/DividerWithCaption";
import {
    Autocomplete,
    Alert
} from "@material-ui/lab";
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import useCoreServices from "../hooks/queries/useCoreServices";
import useServiceTypes from "../hooks/queries/useServiceTypes";
import clientTimesheetService from "../services/clientTimesheetService";
import { downloadFileFromBytes } from "../utils/fileUtils";
import useIsMobile from "../hooks/useIsMobile";
import AccountInfiniteSearch from "../components/AccountInfiniteSearch";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const useStyles = makeStyles((theme) => ({
    heading: {
        color: theme.palette.primary.main,
        margin: `${theme.spacing(6)}px 0`,
    },
    paper: {
        padding: theme.spacing(10, 6),
    },
    fullWidthInput: {
        width: "100%",
    },
    chargeableHeading: {
        fontWeight: theme.typography.fontWeightBold,
        color: theme.palette.primary.main,
        marginBottom: theme.spacing(3),
    },
    csvLink: {
        visibility: "hidden",
    },
    selectedChip:{
        backgroundColor: "#F4F2FE",
        marginRight: theme.spacing(1),
    },
    alert: {
        color: "#B00020",
        marginBottom: theme.spacing(4),
        "& span" : {
            textDecoration: "underline",
            fontWeight: "bold",
        }
    },
    floatingLabel: {
        color:  theme.palette.text.primary,
    },
    rowSpacing: {
        marginBottom: theme.spacing(1),
    },
    radioContainer: {
        marginTop: theme.spacing(3),
    }
}))

const csvHeaders = [
    { label: "Date", key: "date" },
    { label: "Case ID", key: "caseId" },
    { label: "Account Name", key: "accountName" },
    { label: "Adviser", key: "adviser" },
    { label: "Case Description", key: "caseDescription" },
    { label: "Employees", key: "employees" },
    { label: "Case Type", key: "caseType" },
    { label: "Activity Type", key: "activityType" },
    { label: "Private?", key: "isPrivate" },
    { label: "Misc Activity Type", key: "miscActivityType" },
    { label: "Activity Description", key: "activityDescription" },
    { label: "Charging Status?", key: "chargingStatus" },
    { label: "Contract Number", key: "contractNumber" },
    { label: "Minutes", key: "minutes" },
    { label: "Units", key: "units" },
    { label: "Unitised Minutes", key: "unitisedMinutes" },
    { label: "Rate", key: "hourlyRate"},
    { label: "Core Service", key: "coreService" },
    { label: "Service Type", key: "serviceType"},
    { label: "Job Code", key: "jobCode"},
];


const ActivityExport = () => {

    const classes = useStyles();
    const currentDate = new Date();
    const firstDayOfPreviousMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1);
    const lastDayOfPreviousMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 0);
    const csvExportLinkRef = useRef();
    const isMobile = useIsMobile();

    const [fromDate, setFromDate] = useState(firstDayOfPreviousMonth);
    const [toDate, setToDate] = useState(lastDayOfPreviousMonth);
    const [chargeableOptionSelected, setChargeableOptionSelected] = useState(null);
    const [selectedAccount, setSelectedAccount] = useState(null);
    const [selectedCoreServices, setSelectedCoreServices] = useState([]);
    const [selectedServiceTypes, setSelectedServiceTypes] = useState([]);
    const [isDownloadingCsv, setIsDownloadingCsv] = useState(false);
    const [isDownloadingPdfZip, setIsDownloadingPdfZip] = useState(false);
    const [exportData, setExportData] = useState([]);
    const [errors, setErrors] = useState({ coreService: false, account: false });

    const {
        data: coreServices,
        isLoading: isCoreServicesLoading,
        isError: isCoreServicesError,
        error: coreServicesError,
    } = useCoreServices();

    const {
        data: serviceTypes,
        isLoading: isServiceTypesLoading,
        isError: isServiceTypesError,
        error: serviceTypesError,
    } = useServiceTypes({ coreServices: selectedCoreServices })

    // When the service types available to a user change, make sure we clear out any existing selections that are no longer applicable
    useEffect(() => {
        if (serviceTypes){
            setSelectedServiceTypes(prevSelectedServiceTypes =>
            prevSelectedServiceTypes.filter(selectedServiceType =>
                serviceTypes?.some(serviceType => serviceType.name === selectedServiceType.name)
            ));
        }
    }, [serviceTypes]);

    const chargeableOptions = [
        { name: "All", value: null },
        { name: "Chargeable", value: true },
        { name: "Non-Chargeable", value: false }
    ];

    const handleCoreServiceChange = (event, values) => {
        setErrors((prevErrors) => ({...prevErrors, coreService: false, account: false }));
        setSelectedCoreServices(values);
        clearAccountInput();
    }

    const handleServiceTypeChange = (event, values) => {
        setSelectedServiceTypes(values);
    }

    const handleChargeableOptionSelected = (event) => {
        setChargeableOptionSelected(event.target.value === "" ? null : event.target.value === 'true');
    }

    const clearServiceInputs = () => {
        setSelectedCoreServices([]);
        setSelectedServiceTypes([])
    }

    const clearAccountInput = () => {
        setSelectedAccount(null);
    }

    const handleAccountSelected = (account) => {
        setErrors((prevErrors) => ({ ...prevErrors, account: false, coreService: false }));
        setSelectedAccount(account);
        clearServiceInputs();
    }

    const handleAccountCleared = () => {
        setSelectedAccount(null);
    }

    const isValid = useMemo(() => {
        return () => {
            let hasErrors = false;
            if (selectedCoreServices.length === 0 && !selectedAccount){
                setErrors((prevErrors) => ({
                    ...prevErrors,
                    coreService: true,
                    account: true
                }));
                hasErrors = true;
            } else{
                setErrors((prevErrors) => ({
                    ...prevErrors,
                    coreService: false,
                    account: false
                }));
            }
    
            return !hasErrors;
        }
    }, [selectedCoreServices, selectedAccount]);

    const handleCSVExport = useCallback(async () => {

        if (isValid()) {
            setIsDownloadingCsv(true);
            const csvRawContent =
                await clientTimesheetService.getTimesheetByActivity({
                    fromDate,
                    toDate,
                    accountId: selectedAccount?.id,
                    coreServices: selectedCoreServices,
                    serviceTypes: selectedServiceTypes,
                    isChargeable: chargeableOptionSelected,
            });
            const formattedContent = csvRawContent.map((activity) => ({
                date: format(new Date(activity?.date), "dd/MM/yyyy HH:mm") || "N/A",
                caseId: activity.caseId || "N/A",
                accountName: activity.accountName || "N/A",
                adviser: activity.adviser || "N/A",
                caseDescription: activity.caseDescription || "N/A",
                employees: activity.employees || "N/A",
                caseType: activity.caseType || "N/A",
                activityType: activity?.activityType || "N/A",
                miscActivityType: activity.miscActivityType || "N/A",
                activityDescription: activity?.activityDescription || "N/A",
                chargingStatus: activity.isChargeable ? "Chargeable" : activity.isExcluded ? "FREE" : "Included",
                contractNumber: activity.contractNumber || "N/A",
                minutes: activity.isContractUnitised ? "" : activity.minutes || "0",
                units: activity.isContractUnitised ? activity.units || "0" : "",
                unitisedMinutes: activity.isContractUnitised ? activity.unitisedMinutes || "0" : "",
                hourlyRate: activity.hourlyRate || "N/A",
                coreService: activity.coreService || "N/A",
                serviceType: activity.serviceType || "N/A",
                jobCode: activity.jobCode || "N/A",
                isPrivate: activity.isPrivate ? "Private": ""
            }));

            setExportData(formattedContent);
            setTimeout(() => {
                csvExportLinkRef.current.link.click();
            });
            setIsDownloadingCsv(false);
        }
    }, [isValid, fromDate, toDate, selectedAccount?.id, selectedCoreServices, selectedServiceTypes, chargeableOptionSelected]);

    const handlePdfExport = useCallback(async () => {        

        if (isValid()){
            try {
                setIsDownloadingPdfZip(true);
                const bytes = await clientTimesheetService.getTimesheetByAccount({
                    fromDate,
                    toDate,
                    accountId: selectedAccount?.id,
                    coreServices: selectedCoreServices,
                    serviceTypes: selectedServiceTypes,
                    isChargeable: chargeableOptionSelected,
                });
                downloadFileFromBytes({
                    bytes,
                    fileName: `CaseActivityTimesheet${format(
                        new Date(),
                        "yyyyMMddhhmmss"
                    )}.zip`,
                    fileType: "application/zip",
                });
            } catch (e) {
                console.error(e);
            } finally {
                setIsDownloadingPdfZip(false);
            }
        }
    }, [isValid, fromDate, toDate, selectedAccount?.id, selectedCoreServices, selectedServiceTypes, chargeableOptionSelected]);

    return(
        <>
            <Typography variant="h3" className={classes.heading}>Activity Export</Typography>
            <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
            >
                <Grid container spacing={3} justifyContent="center" alignItems="center">
                    <Grid item xs={12} sm={10} md={8} lg={6}>
                        <Paper className={classes.paper}>
                            <Grid container spacing={3}>
                                <Grid item xs={12} md={6} className={classes.rowSpacing}>
                                    <KeyboardDatePicker
                                        className={classes.fullWidthInput}
                                        format="dd/MM/yyyy"
                                        inputVariant="outlined"
                                        label="From Date"
                                        value={fromDate}
                                        onChange={(date) => setFromDate(date)}
                                        maxDate={toDate || new Date()}
                                    >
                                    </KeyboardDatePicker>
                                </Grid>
                                <Grid item xs={12} md={6} className={classes.rowSpacing}>
                                    <KeyboardDatePicker
                                        className={classes.fullWidthInput}
                                        format="dd/MM/yyyy"
                                        inputVariant="outlined"
                                        value={toDate}
                                        onChange={(date) => setToDate(date)}
                                        label="To Date"
                                        minDate={fromDate}
                                        maxDate={new Date()}
                                    >
                                    </KeyboardDatePicker>
                                </Grid>
                                <Grid item xs={12} className={classes.rowSpacing}>
                                    <DividerWithCaption caption="And" />
                                </Grid>
                                <Grid item xs={12} className={classes.rowSpacing}>
                                    { isCoreServicesLoading && <CircularProgress /> }
                                    { isCoreServicesError && (
                                        <Typography>
                                            { coreServicesError?.message || "Failed to load core services"  }
                                        </Typography>
                                    )}
                                    { coreServices &&  (
                                        <Autocomplete
                                            multiple
                                            id="coreservices-multi-select"
                                            options={coreServices}
                                            disableCloseOnSelect
                                            getOptionLabel={(option) => option.name}
                                            onChange={handleCoreServiceChange}
                                            value={selectedCoreServices}
                                            renderOption={(option, { selected }) => (
                                                <React.Fragment>
                                                    <Checkbox
                                                        icon={icon}
                                                        checkedIcon={checkedIcon}
                                                        style={{ marginRight: 8 }}
                                                        checked={selected}
                                                    />
                                                    {option.name}
                                                </React.Fragment>
                                            )}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    variant="outlined"
                                                    label="Core Service"
                                                    placeholder="Core Service..."
                                                    error={errors.coreService}
                                                    helperText={errors.coreService && "Please select a core service"}
                                                    InputLabelProps={{
                                                        className: classes.floatingLabel,
                                                    }}
                                                />
                                            )}
                                            ChipProps={{
                                                className: classes.selectedChip,
                                            }}
                                        />
                                    )}
                                </Grid>
                                <Grid item xs={12} className={classes.rowSpacing}>
                                    { isServiceTypesLoading && <CircularProgress /> }
                                    { isServiceTypesError && (
                                        <Typography>
                                            { serviceTypesError?.message || "Failed to load services types"  }
                                        </Typography>
                                    )}
                                    <Autocomplete
                                        multiple
                                        id="servicetypes-multi-select"
                                        options={serviceTypes || []}
                                        disabled={selectedCoreServices?.length === 0}
                                        disableCloseOnSelect
                                        getOptionLabel={(option) => option.name}
                                        onChange={handleServiceTypeChange}
                                        value={selectedServiceTypes}
                                        getOptionSelected={(option, value) => option.name === value.name }
                                        renderOption={(option, { selected }) => (
                                            <React.Fragment>
                                                <Checkbox
                                                    icon={icon}
                                                    checkedIcon={checkedIcon}
                                                    style={{ marginRight: 8 }}
                                                    checked={selected}
                                                />
                                                {option.name}
                                            </React.Fragment>
                                        )}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                variant="outlined"
                                                label="Service Type"
                                                placeholder="Service Type..."
                                            />
                                        )}
                                        ChipProps={{
                                            className: classes.selectedChip,
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} className={classes.rowSpacing}>
                                    <DividerWithCaption caption="Or" />
                                </Grid>
                                <Grid item xs={12} className={classes.rowSpacing}>
                                    <AccountInfiniteSearch
                                        selectedAccount={selectedAccount}
                                        error={errors.account}
                                        helperText={errors.account && "Please select an account"}
                                        onAccountSelected={handleAccountSelected}
                                        onAccountCleared={handleAccountCleared}
                                    />
                                </Grid>
                                <Grid item xs={12} className={classes.rowSpacing}>
                                    <FormControl component="fieldset" className={classes.radioContainer}>
                                        <FormLabel component="legend" className={classes.chargeableHeading}>
                                            Chargeable
                                        </FormLabel>
                                        <RadioGroup aria-label="chargeable" name="chargeable" value={chargeableOptionSelected} onChange={handleChargeableOptionSelected}>
                                            {
                                                chargeableOptions.map((option, index) =>
                                                    <FormControlLabel key={index} value={option.value} control={<Radio />} label={option.name} />
                                                )}
                                        </RadioGroup>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12}>
                                    {errors.coreService && errors.account && (
                                        <Alert className={classes.alert} severity="error" icon={false}>
                                            Please select a core service <span>or</span> an account.
                                        </Alert>
                                    )}
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container spacing={2} justifyContent={isMobile ? "center" : "flex-end"}>
                                        <Grid item className={isMobile ? classes.fullWidthInput : ""}>
                                            {isDownloadingPdfZip ? (
                                                <CircularProgress />
                                            ) : (
                                                <Button
                                                    variant="contained"
                                                    color="secondary"
                                                    onClick={handlePdfExport}
                                                    className={isMobile ? classes.fullWidthInput : ""}
                                                >
                                                    Export to PDF
                                                </Button>
                                            )}
                                        </Grid>
                                        <Grid item className={isMobile ? classes.fullWidthInput : ""}>
                                            {isDownloadingCsv ? (
                                                <CircularProgress />
                                            ) : (
                                                <Button
                                                    variant="contained"
                                                    color="secondary"
                                                    onClick={handleCSVExport}
                                                    className={isMobile ? classes.fullWidthInput : ""}
                                                >
                                                    Export to CSV
                                                </Button>
                                            )}
                                            <CSVLink
                                                className={classes.csvLink}
                                                ref={csvExportLinkRef}
                                                headers={csvHeaders}
                                                data={exportData}
                                                filename={`CaseActivityTimesheet${format(
                                                    new Date(),
                                                    "yyyyMMddhhmmss"
                                                )}.csv`}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                </Grid>
            </Box>
        </>
    )
}

export default ActivityExport;