import React, { useState, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "../../plugins/axios";
import useIsMobile from "../../hooks/useIsMobile";
import { sortNameCol, sortByCol, caseFileActions } from "../../constants/caseFiles";
import { setCaseFilesForDownload } from "../../redux/actions/caseActions";
import { setSnackAction } from "../../redux/actions/snackActions";
import { downloadFileFromBytes } from "../../utils/fileUtils";

import {
    alpha,
    Box,
    Button,
    IconButton,
    Typography,
    Grid,
    makeStyles,
    Select,
    MenuItem,
    CircularProgress,
    Checkbox,
} from "@material-ui/core";
import {
    CreateNewFolder,
    CreateNewFolderOutlined,
    FolderOutlined as FolderOutlinedIcon,
    ArrowDropDown,
    ArrowDropUp,
    CloudDownload as DownloadIcon,
    CheckBoxOutlined,
    Close as CloseIcon,
} from "@material-ui/icons";

import BreadcrumbsNav from "./BreadcrumbsNav";
import MoveBackNav from "./MoveBackNav";
import File from "./File";
import Folder from "./Folder";
import MobileDownloadMenu from "./MobileDownloadMenu";

const apiUrl = process.env.REACT_APP_CASENEST_API_URL;

const useStyles = makeStyles((theme) => ({
    iconButtonColor: {
        backgroundColor: "#f2edff !important",
    },
    folderColor: {
        fill: "#5c45c7"
    },
    emptyTitle: {
        marginTop: theme.spacing(10),
    },
    buttonOutline: {
        backgroundColor: "transparent",
        border: `1px solid ${alpha(theme.palette.primary.main, 0.3)}`,
        marginLeft: theme.spacing(2),
        border: `1px solid #8d87aa`,
        color: "#5c45c7",
        backgroundColor: "white",
    },
    emptyContainer: {
        flex: "1 1 100%",
        alignItems: "center",
        justifyContent: "center",
        display: "flex",
        textAlign: "center",
        marginTop: theme.spacing(6),
        marginBottom: theme.spacing(6),
        "& .MuiIconButton-root": {
            cursor: "default",
            transform: "scale(1.8)"
        },
    },
    filesTable: {
        "& .MuiGrid-container": {
            alignItems: "center",
        }
    },
    moreMenu: {
        textAlign: "right",
        [theme.breakpoints.up("sm")]: {
            flexGrow: 1,
            maxWidth: "none",
        },
    },
    tableRowHeading: {
        padding: "16px 0",
        borderBottom: "1px solid rgba(224, 224, 224, 1)",
    },
    maxGutter: {
        maxWidth: "45px",
    },
    mobileOptionRow: {
        marginTop: "9px",
        marginBottom: "9px",
    },
    mobileDropdown: {
        border: `1px solid #8d87aa`,
        color: "#5c45c7",
        borderRadius: "6px",
        padding: "4px 10px",
        backgroundColor: "white",
        fontWeight: "500",
        "&:before, &:after": {
            content: "none",
            borderBottom: "none"
        },
        "& .MuiSelect-select:focus": {
            backgroundColor: "white",
        },
        "& .MuiSelect-select": {
            paddingRight: "0",
        }
    },
    inProgress: {
        marginRight: theme.spacing(5),
    },
    saveStructureButton: {
        marginRight: theme.spacing(2),
    },
    buttonClear: {
        minWidth: "35px",
        marginRight: "4px",
        "& .MuiButton-startIcon": {
            marginLeft: "-4px",
            marginRight: "-4px",
        }
    },
    selectedText: {
        paddingRight: "6px",
    },
}));

const maxFolderChildren = 5;

const findFolderLevel = (treeData, targetFolderId, currentLevel = 1) => {
    for (const folder of treeData) {
        if (folder.folderId === targetFolderId) {
            return currentLevel + 1;
        } else if (folder.children && currentLevel < maxFolderChildren) {
            const childLevel = findFolderLevel(folder.children, targetFolderId, currentLevel + 1);
            if (childLevel !== -1) {
                return childLevel;
            }
        }
    }
    return -1;
};

const TreeContent = ({
    treeData,
    setTreeData,
    handleOpenContextMenu,
    isMove,
    setFolderDialogOpen,
    newParent,
    setNewParent,
    breadcrumbs,
    setBreadcrumbs,
    moveParent,
    setMoveParent,
    sortBy,
    setSortBy,
    sortName,
    setSortName,
    mobileSort,
    setMobileSort,
    caseId,
    findFolderById,
    updateTreeData,
    selectedItem,
}) => {
    const dispatch = useDispatch();
    const isMobile = useIsMobile();
    const classes = useStyles();
    const { cases } = useSelector((state) => state.caseReducer);
    const [moveBreadcrumbs, setMoveBreadcrumbs] = useState(breadcrumbs ? breadcrumbs : []);
    const [currentFolder, setCurrentFolder] = useState(null);
    const [currentLevel, setCurrentLevel] = useState(1);
    const [displayDownloadButton, setDisplayDownloadButton] = useState(false);
    const [downloadInProgress, setDownloadInProgress] = useState(false);
    const caseFilesForDownload = cases[caseId]?.caseFilesForDownload ?? [];
    const [countFilesSelected, setCountFilesSelected] = useState(0);
    const [allChecked, setAllChecked] = useState([]);

    useEffect(() => {
        // Update currentFolder whenever treeData, selectedItem, or moveParent changes
        const newFolder = !selectedItem && newParent?.folderId ? newParent : selectedItem && moveParent?.folderId ? moveParent : null;
        setCurrentFolder(newFolder);
    }, [selectedItem, newParent]);

    useEffect(() => {
        //Uncheck all checked items if file moved or deleted
        if (caseId) { 
            dispatch(setCaseFilesForDownload([], caseId));
            setAllChecked([]);
            setCountFilesSelected(0);
            setDisplayDownloadButton(false);
        }
    }, [treeData]);

    const countFiles = (treeList, nextNumber = 0) => {
        for (let i = 0; i < treeList.length; i++) {
            const f = treeList[i];
            if (f.azureFileMetadata) {
                nextNumber++;
            }
            if (f.children && f.children.length > 0) {
                nextNumber = countFiles(f.children, nextNumber);
            }
        }
        return nextNumber;
    };

    const handleAttachmentChecked = (itemDownload, checked) => {
        if (!checked) {
            var filesToBeDownloaded = updateTreeData(caseFilesForDownload, itemDownload, newParent, caseFileActions.REMOVE);
            dispatch(setCaseFilesForDownload(filesToBeDownloaded, caseId));
            setDisplayDownloadButton(filesToBeDownloaded?.length > 0);
            setCountFilesSelected(countFiles(filesToBeDownloaded));
            return;
        }
        if (itemDownload.folderId) itemDownload = findFolderById(treeData, itemDownload.folderId);
        dispatch(
            setCaseFilesForDownload(
                [...caseFilesForDownload, itemDownload],
                caseId
            )
        );
        setDisplayDownloadButton(true);
        setCountFilesSelected(countFiles([...caseFilesForDownload, itemDownload]));
    };

    const handleAllChecked = (checked) => {
        let updatedCaseFilesDownload = caseFilesForDownload;
        let levelItems = treeData;

        if (!checked) {
            if (newParent == null) {
                dispatch(setCaseFilesForDownload([], caseId));
                setAllChecked([]);
                setCountFilesSelected(0);
                setDisplayDownloadButton(false);
            } else {
                levelItems = findFolderById(treeData, newParent.folderId).children;
                let updatedAllChecked = allChecked;
                for (let i = 0; i < levelItems.length; i++) {
                    const currentItem = levelItems[i];
                    updatedAllChecked = updatedAllChecked.filter((item) => item != newParent.folderId);
                    updatedCaseFilesDownload = updatedCaseFilesDownload.filter((item) => item.docType === "Folder" ? item.folderId != currentItem.folderId : item.azureFileMetadata?.azureFileReference !== currentItem.azureFileMetadata?.azureFileReference);
                }
                let updatedCountFilesSelected = countFiles(updatedCaseFilesDownload);
                dispatch(
                    setCaseFilesForDownload(
                        updatedCaseFilesDownload,
                        caseId
                    )
                );
                setAllChecked(updatedAllChecked);
                setCountFilesSelected(updatedCountFilesSelected);
                if (updatedCountFilesSelected == 0) {
                    setDisplayDownloadButton(false);
                }
            }
            return;
        }
        if (newParent != null) {
            levelItems = findFolderById(treeData, newParent.folderId).children
        }
        for (let i = 0; i < levelItems.length; i++) {
            const currentItem = levelItems[i];
            if (currentItem.folderId && !updatedCaseFilesDownload.some(item => item.folderId === currentItem.folderId)) {
                updatedCaseFilesDownload.push(findFolderById(treeData, currentItem.folderId));
            } else if (currentItem.azureFileMetadata && !updatedCaseFilesDownload.some(item => item.azureFileMetadata?.azureFileReference === currentItem.azureFileMetadata.azureFileReference)) {
                //do not download precedent files
                updatedCaseFilesDownload.push(currentItem);
            }
        }
        dispatch(
            setCaseFilesForDownload(
                updatedCaseFilesDownload,
                caseId
            )
        );
        setDisplayDownloadButton(true);
        setCountFilesSelected(countFiles(updatedCaseFilesDownload));
        setAllChecked([...allChecked, newParent ? newParent.folderId : 'root'])
    };

    const handleDownload = async () => {
        try {
            setDownloadInProgress(true);
            const response = await axios.post(
                `${apiUrl}/downloadcaseattachments/${caseId}`,
                { CaseAttachments: caseFilesForDownload },
                {
                    responseType: "arraybuffer",
                }
            );
            downloadFileFromBytes({
                bytes: response.data,
                fileName: `Case${caseId}.zip`,
                fileType: "application/zip",
            });
        } catch (e) {
            console.error(e);
            dispatch(
                setSnackAction(
                    "Could not download zip attachment file",
                    "error"
                )
            );
        } finally {
            setDownloadInProgress(false);
        }
    };

    const handleCancel = () => {
        dispatch(setCaseFilesForDownload([], caseId));
        setDisplayDownloadButton(false);
    }

    const mobileSortChange = (event) => {
        const [name, order] = event.target.value.split('-');
        toggleSortBy(name, order);
    };

    const toggleSortBy = (newName, newSort = null) => {
        const updatedItems = [...treeData];

        if (newSort == null) newSort = sortBy === sortByCol.DESC ? sortByCol.ASC : sortByCol.DESC;

        setSortName(newName);
        setSortBy(newSort);
        setMobileSort(newName + "-" + newSort);

        const reorder = (updatedItems) => {
            for (let i = 0; i < updatedItems.length; i++) {
                let folders = updatedItems
                    .filter((p) => p.docType === "Folder");
                let files = updatedItems
                    .filter((p) => p.docType === "File");

                if (newName === sortNameCol.DATE) {
                    files = files
                        .sort((a, b) => {
                            return new Date(a[newName]).getTime() - new Date(b[newName]).getTime()
                        });
                }

                if (newName === sortNameCol.NAME) {
                    files = files
                        .sort((a, b) => a[newName].localeCompare(b[newName]));
                }

                if (newSort === sortByCol.DESC) {
                    files.reverse();
                }

                updatedItems = [...folders, ...files];

                if (updatedItems[i].children) {
                    reorder(updatedItems[i].children);
                }
            }
            return updatedItems;
        };

        var newOrder = reorder(updatedItems);
        setTreeData(newOrder)
    };

    const navigateToFolder = useCallback((folder) => {
        setCurrentFolder(folder);
        !selectedItem ? setBreadcrumbs([...breadcrumbs, folder]) : setMoveBreadcrumbs([...moveBreadcrumbs, folder]);
        !selectedItem ? setNewParent(folder) : setMoveParent(folder);
        setCurrentLevel(findFolderLevel(treeData, folder.folderId));
    }, [breadcrumbs, moveBreadcrumbs, treeData]);

    const navigateToParentFolder = useCallback((index) => {
        if (index === -1) {
            setCurrentFolder(null);
            !selectedItem ? setBreadcrumbs([]) : setMoveBreadcrumbs([]);
            !selectedItem ? setNewParent(null) : setMoveParent(null);
            setCurrentLevel(1);
        } else {
            const newPath = !selectedItem ? breadcrumbs.slice(0, index + 1) : moveBreadcrumbs.slice(0, index + 1);
            const newCurrentFolder = newPath[newPath.length - 1];
            setCurrentFolder(newCurrentFolder);
            !selectedItem ? setBreadcrumbs(newPath) : setMoveBreadcrumbs(newPath);
            !selectedItem ? setNewParent(newCurrentFolder) : setMoveParent(newCurrentFolder);
            setCurrentLevel(findFolderLevel(treeData, newCurrentFolder.folderId));
        }
    }, [breadcrumbs, moveBreadcrumbs, treeData]);

    const getContent = (folder) => {
        if (!folder) {
            return treeData && treeData.map((item) => (
                item.docType === 'Folder' ? (
                    <Folder
                        key={item.folderId}
                        folder={item}
                        handleOpenContextMenu={handleOpenContextMenu}
                        isMove={isMove}
                        handleAttachmentChecked={handleAttachmentChecked}
                        caseFilesForDownload={caseFilesForDownload}
                        displayDownloadButton={displayDownloadButton}
                        navigateToFolder={navigateToFolder}
                        selectedItem={selectedItem}
                    />
                ) : (
                    <File
                        key={item.azureFileMetadata ? item.azureFileMetadata.azureFileReference : item.precedentFileMetadata.precedentIdentifier}
                        file={item}
                        handleOpenContextMenu={handleOpenContextMenu}
                        isMove={isMove}
                        handleAttachmentChecked={handleAttachmentChecked}
                        caseFilesForDownload={caseFilesForDownload}
                        displayDownloadButton={displayDownloadButton}
                    />
                )
            ));
        } else if (folder.children && folder.children.length > 0) {
            return (folder.children.map((item) => (
                item.docType === 'Folder' ? (
                    <Folder
                        key={item.folderId}
                        folder={item}
                        handleOpenContextMenu={handleOpenContextMenu}
                        isMove={isMove}
                        handleAttachmentChecked={handleAttachmentChecked}
                        caseFilesForDownload={caseFilesForDownload}
                        displayDownloadButton={displayDownloadButton}
                        navigateToFolder={navigateToFolder}
                        selectedItem={selectedItem}
                    />
                ) : (
                    <File
                        key={item.azureFileMetadata ? item.azureFileMetadata.azureFileReference : item.precedentFileMetadata.precedentIdentifier}
                        file={item}
                        handleOpenContextMenu={handleOpenContextMenu}
                        isMove={isMove}
                        handleAttachmentChecked={handleAttachmentChecked}
                        caseFilesForDownload={caseFilesForDownload}
                        displayDownloadButton={displayDownloadButton}
                    />
                )
            )));
        } else {
            return []; // Return null when there's no content to display
        }
    };
    const content = getContent(currentFolder);
    return (
        <>
            <Box display="flex">
                <Box flexGrow={1} alignItems="center">
                    {isMove ? (
                        <MoveBackNav
                            path={moveBreadcrumbs}
                            navigateToParentFolder={navigateToParentFolder}
                        />
                    ) : (
                        <BreadcrumbsNav
                            path={breadcrumbs}
                            navigateToParentFolder={navigateToParentFolder}
                        />
                    )}
                </Box>
                <Box>
                    {!isMobile && !isMove &&
                        (
                            <>
                                {downloadInProgress ?
                                    (
                                        <CircularProgress className={classes.inProgress} />
                                    ) : (
                                        displayDownloadButton && (
                                            <>
                                                <Button
                                                    color="primary"
                                                    startIcon={<CloseIcon />}
                                                    onClick={() => handleAllChecked(false)}
                                                    className={classes.buttonClear}
                                                ></Button>
                                                <span className={classes.selectedText}>{countFilesSelected} selected</span>
                                                <Button
                                                    startIcon={<DownloadIcon />}
                                                    className={
                                                        classes.buttonOutline
                                                    }
                                                    variant="contained"
                                                    onClick={handleDownload}
                                                >
                                                    Download
                                                </Button>
                                            </>
                                        )
                                    )
                                }
                            </>
                        )
                    }
                    {currentLevel < maxFolderChildren && (
                        <>
                            {isMobile || isMove ?
                                (
                                    <IconButton className={classes.iconButtonColor} onClick={() => setFolderDialogOpen(true)}>
                                        <CreateNewFolder className={classes.folderColor} />
                                    </IconButton>
                                )
                                :
                                (
                                    <Button
                                        startIcon={<CreateNewFolderOutlined />}
                                        variant="contained"
                                        className={classes.buttonOutline}
                                        onClick={() => setFolderDialogOpen(true)}
                                    >
                                        New Folder
                                    </Button>
                                )
                            }
                        </>
                    )}
                </Box>
            </Box>
            {isMobile && !isMove && (
                <Box display="flex" className={classes.mobileOptionRow}>
                    <Box flexGrow={1} alignItems="center">
                        <Select
                            value={mobileSort}
                            onChange={mobileSortChange}
                            inputProps={{ IconComponent: () => null }}
                            className={classes.mobileDropdown}
                            disableAnimation={true}
                        >
                            <MenuItem value={sortNameCol.DATE + "-" + sortByCol.ASC}>
                                Added (oldest)
                            </MenuItem>
                            <MenuItem value={sortNameCol.DATE + "-" + sortByCol.DESC}>
                                Added (newest)
                            </MenuItem>
                            <MenuItem value={sortNameCol.NAME + "-" + sortByCol.ASC}>
                                Name (a-z)
                            </MenuItem>
                            <MenuItem value={sortNameCol.NAME + "-" + sortByCol.DESC}>
                                Name (z-a)
                            </MenuItem>
                        </Select>
                    </Box>
                    <Box>
                        {displayDownloadButton ?
                            (
                                <>
                                    <Button
                                        className={classes.buttonOutline}
                                        variant="contained"
                                        onClick={handleCancel}
                                    >
                                        Cancel
                                    </Button>
                                </>
                            )
                            :
                            (
                                <>
                                    <Button
                                        startIcon={<CheckBoxOutlined />}
                                        className={classes.buttonOutline}
                                        variant="contained"
                                        onClick={() => setDisplayDownloadButton(true)}
                                    >
                                        Select
                                    </Button>
                                </>
                            )
                        }
                    </Box>
                </Box>
            )}

            {treeData && content.length > 0 ? (
                <div className={classes.filesTable}>
                    {!isMove && !isMobile &&
                        <Grid container className={classes.tableRowHeading}>
                            {!isMove && ((isMobile && displayDownloadButton) || !isMobile) &&
                                <Grid item xs={1} className={classes.maxGutter}>
                                    <Checkbox
                                        checked={allChecked.find(current => newParent === null ? current === 'root' : current == newParent.folderId) ? true : false}
                                        onChange={(event) => handleAllChecked(event.target.checked)}
                                    />
                                </Grid>
                            }
                            <Grid item xs={1} className={classes.maxGutter}>
                                <Button
                                    style={{
                                        paddingLeft: "20px",
                                        paddingRight: "20px",
                                        marginLeft: "-20px",
                                        marginRight: "-20px",
                                    }}
                                    onClick={() => toggleSortBy(sortNameCol.NAME)}
                                >
                                    Name
                                    {sortName === sortNameCol.NAME && sortBy === sortByCol.DESC && <ArrowDropDown />}
                                    {sortName === sortNameCol.NAME && sortBy === sortByCol.ASC && <ArrowDropUp />}
                                </Button>
                            </Grid>
                            <Grid item container xs={isMove ? 10 : (isMobile && displayDownloadButton ? 8 : 9)}>
                                <Grid item s={12} md={8}></Grid>
                                <Grid item container s={12} md={4}>
                                    <Button
                                        style={{
                                            paddingLeft: "20px",
                                            paddingRight: "20px",
                                            marginLeft: "-20px",
                                            marginRight: "-20px",
                                        }}
                                        onClick={() => toggleSortBy(sortNameCol.DATE)}
                                    >
                                        Added
                                        {sortName === sortNameCol.DATE && sortBy === sortByCol.DESC && <ArrowDropDown />}
                                        {sortName === sortNameCol.DATE && sortBy === sortByCol.ASC && <ArrowDropUp />}
                                    </Button>
                                </Grid>
                            </Grid>
                            <Grid item xs={1} className={classes.moreMenu}></Grid>
                        </Grid>
                    }
                    {getContent(currentFolder)}
                </div>
            ) : (
                <div className={classes.emptyContainer}>
                    <div>
                        <IconButton className={classes.iconButtonColor}>
                            <FolderOutlinedIcon className={classes.folderColor} />
                        </IconButton>
                        <Typography
                            variant="h3"
                            color="textPrimary"
                            className={classes.emptyTitle}
                        >
                            This folder is empty
                        </Typography>
                    </div>
                </div>
            )}
            {isMobile && displayDownloadButton && (
                <MobileDownloadMenu
                    handleDownload={handleDownload}
                    countFilesSelected={countFilesSelected}
                />
            )}
        </>
    );
};

export default TreeContent;