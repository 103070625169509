import React, { useState, useEffect, useMemo } from "react";
import { Grid } from "@material-ui/core";
import { useForm, Form } from "../helpers/useForm";
import { Controls } from "../helpers/Controls";
import {
    setCreateContactEmail,
    setCreateContactPhone,
} from "../redux/actions/createContactActions";
import { connect, useSelector } from "react-redux";
import {
    Box,
    Card,
    CardContent,
    Checkbox,
    Collapse,
    FormControlLabel,
    InputAdornment,
    Table,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TableBody,
    Typography,
    IconButton,
    FormControl,
    FormLabel,
} from "@material-ui/core";
import { bindActionCreators } from "redux";
import { actionCreators } from "../redux/reducers/accountReducer";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import DupeMatchIcon from "@material-ui/icons/ReportProblemRounded";
import UpdateRecordIcon from "@material-ui/icons/Undo";
import { emailIsValid } from "../utils/validators";
import { isStringEmptyOrWhitespaceOnly } from "../helpers/isStringEmptyOrWhitespaceOnly";
import anonymousNumber from "../constants/anonymousNumber";
import useAdviceTypes from "../hooks/queries/useAdviceTypes";
import useAdviceTypesForUser from "../hooks/queries/useAdviceTypesForUser";
import useAccountContracts from "../hooks/queries/useAccountContracts";
import AdviceTypeChip from "../components/AdviceTypeChip";

const initialFValues = {
    id: 0,
    firstName: "",
    lastName: "",
    fullname: "",
    position: "",
    phone: "",
    mobile: "",
    email: "",
    email2: "",
    accountId: "",
    isKeyAccount: "Unknown",
    emailOptOut: "",
    adviceTypeIds: []
};

const CreateContact = ({
    createContactState,
    addOrEdit,
    recordForEdit,
    possibleDupes,
    clickable,
    checkForDupes,
    createLink,
    accountId,
}) => {
    const {
        values,
        setValues,
        errors,
        setErrors,
        handleInputChange,
        resetForm,
    } = useForm(initialFValues);

    const [dupeRecs, setDupeRecs] = useState();
    const [submitClicked, setSubmitClicked] = useState();
    const [submitLinkClicked, setSubmitLinkClicked] = useState(false);

    const { userProfile } = useSelector(state => state.userReducer);
    const { data: userAdviceTypes } = useAdviceTypesForUser(userProfile.userId);

    const { data: adviceTypes } = useAdviceTypes();

    const { data: contracts } = useAccountContracts(accountId);

    const allowedAdviceTypes = useMemo(() => {
        
        if (!userAdviceTypes || !contracts)
            return [];

        let value = adviceTypes;

        value = value.filter(at => userAdviceTypes.includes(at.id));

        let contractAdviceTypeIds = [];

        contracts.forEach(c => {
            if (c.adviceTypeId && !contractAdviceTypeIds.includes(c.adviceTypeId))
                contractAdviceTypeIds.push(c.adviceTypeId);

            if (c.additionalAdviceTypeId && !contractAdviceTypeIds.includes(c.additionalAdviceTypeId))
                contractAdviceTypeIds.push(c.additionalAdviceTypeId);
        });      

        value = value.filter(at => contractAdviceTypeIds.includes(at.id));

        return value;
    }, [userAdviceTypes, contracts, adviceTypes]);

    const validate = () => {
        let temp = {};
        temp.firstName = values.firstName ? "" : "Field is required";
        temp.lastName = values.lastName ? "" : "Field is required";
        if (values.email)
            temp.email = emailIsValid(values.email.trim())
                ? ""
                : "email entered is not valid";
        if (values.email2)
            temp.email2 = emailIsValid(values.email2.trim())
                ? ""
                : "email entered is not valid";

        temp.firstName =
            values.firstName && values.firstName.length > 40
                ? "First name field cannot exceed 40 characters"
                : "";
        temp.lastName =
            values.lastName && values.lastName.length > 80
                ? "Last name field cannot exceed 80 characters"
                : "";

        if (isStringEmptyOrWhitespaceOnly(values.firstName))
            temp.firstName = "First name cannot be blank.";

        if (isStringEmptyOrWhitespaceOnly(values.lastName))
            temp.lastName = "Last name cannot be blank.";

        setErrors({ ...temp });
        return Object.values(temp).every((x) => x == "");
    };

    const handlePhoneInputChange = async (e) => {
        const { name, value } = e.target;
        var validText = true;
        //only allow numbers, spaces or empty strings
        if (name === "mobile" || name === "phone")
            validText = /^[+]*(?=.*\d)[\d ]+$/.test(value);
        if (
            (name === "mobile" || name === "phone") &&
            value === anonymousNumber
        )
            return false;
        if (validText || value === "") {
            handleInputChange(e);
        }
    };

    const handleSubmit = async (e) => {
        setSubmitClicked(true);
        e.preventDefault();
        if (validate()) {
            //if the dupe check has been done followed by a submit then hide the link button also
            if (!checkForDupes) setSubmitLinkClicked(true);
            //addOrEdit is called on the parent ContactsCard.js page

            addOrEdit(values, checkForDupes);
        } else {
            setSubmitClicked(false);
        }
    };

    const handleSubmitLink = async (contactId) => {
        //disable both of the submit buttons
        setSubmitLinkClicked(true);
        setSubmitClicked(true);
        //call the createLink in the parent ContactsCard
        createLink(contactId);
    };

    useEffect(() => {
        if (recordForEdit != null)
            setValues({ ...recordForEdit });
    }, [recordForEdit, setValues]);

    useEffect(() => {

        if (possibleDupes != null)
            setDupeRecs(possibleDupes);

        setSubmitClicked(false);

    }, [possibleDupes]);

    const handleEmailOptOutChange = event => {
        //values.emailOptOut = event.target.checked;
        setValues({
            ...values,
            emailOptOut: event.target.checked,
        });
    };

    const toggleAdviceTypeId = (id) => {
        let ids = values.adviceTypeIds.includes(id)
            ? values.adviceTypeIds.filter(x => x !== id)
            : [...values.adviceTypeIds, id];

        setValues({
            ...values,
            adviceTypeIds: ids
        });
    }

    function Row(results) {
        const { row } = results;
        const [open, setOpen] = React.useState(false);

        const accountIds = row.accounts.map(a => a.accountId);
        const linkExists = accountIds.includes(accountId);

        return (
            <React.Fragment>
                <TableRow
                    key={row.contactId}
                    style={{
                        cursor: clickable ? "pointer" : "default",
                        height: "100%",
                    }}
                    hover={clickable ? true : false}
                    onClick={() => clickable && setOpen(!open)}
                >
                    <TableCell>
                        <IconButton
                            aria-label="expand row"
                            size="small"
                            onClick={() => setOpen(!open)}
                        >
                            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                        </IconButton>
                    </TableCell>
                    <TableCell component="th" scope="row">{row.name}</TableCell>
                    <TableCell>{row.email}</TableCell>
                    <TableCell>{row.phone}</TableCell>
                    <TableCell>{row.mobile}</TableCell>
                    <TableCell>
                        <Controls.ButtonControl
                            onClick={() => handleSubmitLink(row.contactId)}
                            name="submitLinkBtn"
                            size="small"
                            disabled={linkExists || submitLinkClicked}
                            text={linkExists ? "Linked" : "Link"}
                        />
                    </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell
                        style={{
                            paddingBottom: 0,
                            paddingTop: 0,
                            backgroundColor: "#f9f9f9",
                        }}
                        colSpan={6}
                    >
                        <Collapse in={open} timeout="auto" unmountOnExit>
                            <Box margin={1}>
                                <Typography
                                    variant="h6"
                                    gutterBottom
                                    component="div"
                                >
                                    Currently Linked Accounts
                                </Typography>
                                <Table size="small" aria-label="purchases">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Company Name</TableCell>
                                            <TableCell>Address</TableCell>
                                            <TableCell>Postcode</TableCell>
                                            <TableCell>Tel</TableCell>
                                            <TableCell>Partnership</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {row.accounts.map((historyRow) => (
                                            <TableRow
                                                key={historyRow.accountId}
                                                hover={clickable ? true : false}
                                            >
                                                <TableCell component="th" scope="row">{historyRow.name}</TableCell>
                                                <TableCell>{historyRow.address}</TableCell>
                                                <TableCell>{historyRow.postCode}</TableCell>
                                                <TableCell>{historyRow.phone}</TableCell>
                                                <TableCell>{historyRow.partnershipName}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </Box>
                        </Collapse>
                    </TableCell>
                </TableRow>
            </React.Fragment>
        );
    }

    return (
        <Form>
            <Grid container>
                <Grid item xs={6}>
                    <Controls.InputControl
                        name="firstName"
                        label="First Name"
                        value={values.firstName || ""}
                        onChange={handleInputChange}
                        error={errors.firstName}
                    />
                    <Controls.InputControl
                        name="lastName"
                        label="Last Name"
                        value={values.lastName || ""}
                        onChange={handleInputChange}
                        error={errors.lastName}
                    />
                    <Controls.InputControl
                        name="position"
                        label="Job Title"
                        fieldMaxLength="128"
                        value={values.position}
                        onChange={handleInputChange}
                        error={errors.position}
                    />
                    <Controls.InputControl
                        name="phone"
                        label="Phone Number"
                        fieldMaxLength="50"
                        inputPropsAdded={
                            createContactState.contact.phone
                                ? {
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                size="small"
                                                onClick={(e) => {
                                                    values.phone = createContactState.contact.phone;
                                                    handlePhoneInputChange(e);
                                                }}
                                            >
                                                <UpdateRecordIcon size="small" />
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }
                                : {}
                        }
                        value={values.phone}
                        onChange={handlePhoneInputChange}
                        error={errors.phone}
                    />
                </Grid>
                <Grid item xs={6}>
                    <Controls.InputControl
                        name="mobile"
                        label="Mobile Number"
                        fieldMaxLength="50"
                        inputPropsAdded={
                            createContactState.contact.phone
                                ? {
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                size="small"
                                                onClick={e => {
                                                    values.mobile = createContactState.contact.phone;
                                                    handlePhoneInputChange(e);
                                                }}
                                            >
                                                <UpdateRecordIcon size="small" />
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }
                                : {}
                        }
                        value={values.mobile}
                        onChange={handlePhoneInputChange}
                        error={errors.mobile}
                        type="number"
                    />
                    <Controls.InputControl
                        name="email"
                        label="Email"
                        fieldMaxLength="320"
                        inputPropsAdded={
                            createContactState.contact.email
                                ? {
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                size="small"
                                                onClick={(e) => {
                                                    values.email = createContactState.contact.email;
                                                    handleInputChange(e);
                                                }}
                                            >
                                                <UpdateRecordIcon size="small" />
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }
                                : {}
                        }
                        value={values.email == undefined ? "" : values.email.trim()}
                        onChange={handleInputChange}
                        error={errors.email}
                    />
                    <Controls.InputControl
                        name="email2"
                        label="Second Email"
                        fieldMaxLength="320"
                        inputPropsAdded={
                            createContactState.contact.email
                                ? {
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                size="small"
                                                onClick={(e) => {
                                                    values.email2 =
                                                        createContactState.contact.email;
                                                    handleInputChange(e);
                                                }}
                                            >
                                                <UpdateRecordIcon size="small" />
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }
                                : {}
                        }
                        value={values.email2 == undefined ? "" : values.email2.trim()}
                        onChange={handleInputChange}
                        error={errors.email2}
                    />
                    {allowedAdviceTypes.length > 0 &&
                        <FormControl>
                            <FormLabel style={{ marginBottom: '5px' }}>Can Receive Advice Types</FormLabel>
                            <Box display="flex">
                                {allowedAdviceTypes.map(at =>
                                    <AdviceTypeChip onClick={() => toggleAdviceTypeId(at.id)}
                                        type="button"
                                        notSelected={!values.adviceTypeIds.includes(at.id)}
                                        key={at.id}
                                        adviceType={at.name}
                                    />
                                )}
                            </Box>
                        </FormControl>
                    }
                    <FormControlLabel
                        control={
                            <Checkbox
                                name="emailOptOut"
                                checked={values.emailOptOut || false}
                                onChange={handleEmailOptOutChange}
                            />
                        }
                        label="Tick this box if the contact does not consent to email marketing."
                    />
                    <div>
                        {
                            values.id === 0 &&
                            <Controls.ButtonControl
                                text="Reset"
                                color="default"
                                size="small"
                                onClick={resetForm}
                            />
                        }
                    </div>
                    <Grid item xs={12} style={{ alignSelf: "flex-end" }}>
                        <Box display="flex" justifyContent="flex-end">
                            <Controls.ButtonControl
                                onClick={handleSubmit}
                                name="submitBtn"
                                disabled={submitClicked}
                                size="small"
                                text={checkForDupes ? "Submit" : "Create Anyway"}
                            />
                        </Box>
                    </Grid>
                </Grid>
            </Grid>
            {dupeRecs && dupeRecs.length > 0 && (
                <Card>
                    <CardContent>
                        <Box display="flex">
                            <Box>
                                <DupeMatchIcon size="small" />
                            </Box>
                            <Box
                                display="flex"
                                flexGrow={1}
                                flexDirection="column"
                                justifyContent="center"
                                alignItems="center"
                            >
                                <Typography variant="h6">Possible record already exists warning, matching records are listed below</Typography>
                            </Box>
                            <Box>
                                <DupeMatchIcon size="small" />
                            </Box>
                        </Box>
                        <TableContainer>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell></TableCell>
                                        <TableCell>Contact Name</TableCell>
                                        <TableCell>Email</TableCell>
                                        <TableCell>Phone</TableCell>
                                        <TableCell>Mobile Phone</TableCell>
                                        <TableCell>Link</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {dupeRecs.map(row => <Row key={row.contactId} row={row} />)}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </CardContent>
                </Card>
            )}
        </Form>
    );
};

const mapStateToProps = (state) => ({
    createContactState: state.createContactReducer,
});

const mapDispatchToProps = (dispatch) => {
    return {
        ...bindActionCreators(actionCreators, dispatch),
        setCreateContactPhone: (phone) => dispatch(setCreateContactPhone(phone)),
        setCreateContactEmail: (email) => dispatch(setCreateContactEmail(email)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateContact);
